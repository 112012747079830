/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const providerActivate = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Activate', method: methods.HTTP_POST, data });
export const providerArchive = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Archive', method: methods.HTTP_POST, data });
export const providerBlock = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Block', method: methods.HTTP_POST, data });
export const providerCreate = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Create', method: methods.HTTP_POST, data });
export const providerGet = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Get', method: methods.HTTP_POST, data });
export const providerListGet = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Index', method: methods.HTTP_POST, data });
export const providerSearchByText = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/SearchByText', method: methods.HTTP_POST, data });
export const providerUpdate = (data = {}) => transport({ url: '/twirp/tt.learning.catalogue.providers.v1.ProvidersAPI/Update', method: methods.HTTP_POST, data });

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const activateApp = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/Activate', method: methods.HTTP_POST, data });
export const changeBundleName = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/ChangeBundleName', method: methods.HTTP_POST, data });
export const createApp = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/Create', method: methods.HTTP_POST, data });
export const createLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Create', method: methods.HTTP_POST, data });
export const deactivateApp = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/Deactivate', method: methods.HTTP_POST, data });
export const deactivateLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Deactivate', method: methods.HTTP_POST, data });
export const getLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Get', method: methods.HTTP_POST, data });
export const grantLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Grant', method: methods.HTTP_POST, data });
export const indexApp = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/Index', method: methods.HTTP_POST, data });
export const indexAppWithPagination = (data = {}) => transport({ url: '/twirp/tt.core.license.apps.v1.AppsAPI/Filter', method: methods.HTTP_POST, data });
export const indexLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Index', method: methods.HTTP_POST, data });
export const revokeLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Revoke', method: methods.HTTP_POST, data });
export const updateLicense = (data = {}) => transport({ url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Update', method: methods.HTTP_POST, data });
export const licenseByCompanyOrAccount = ({ companyId, accountId }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.license.licences.v1.LicencesAPI/ByCompanyOrAccount', data: { companyId, accountId }, ...options });
export const deactivate = ({ id }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.license.licences.v1.LicencesAPI/Deactivate', data: { id }, ...options });

import { AppOnboardingAsideLeft as AppAsideLeft, AppNav, HolyGrailLayout } from '@front.backoffice/shared';
import * as Names from './app.routes.names';

const MODULE_PREFIX = '/app/onboarding';

export default [
  {
    path: MODULE_PREFIX,
    component: HolyGrailLayout,
    children: [
      {
        path: '',
        name: Names.R_ONBOARDING_HOME,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/Home.vue'),
        },
      },
    ],
  },
];

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

/** VIDEO */
/* TODO разбраться с парамерами запроса чтобы одинаково */
export const videoCreate = (data = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.videos.v1.VideosAPI/Create', method: methods.HTTP_POST, data });
export const videoGet = (data = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.videos.v1.VideosAPI/Get', method: methods.HTTP_POST, data });
export const videoUpdate = (data = {}) => transport({ url: 'twirp/tt.learning.atoms.multicontent.videos.v1.VideosAPI/Update', method: methods.HTTP_POST, data });
export const videoUpload = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.videos.v1.VideosAPI/Upload', method: methods.HTTP_POST, data, ...options });

/** SANDBOX */
export const sandboxCreate = (data = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.sandbox.v1.SandboxAPI/Create', method: methods.HTTP_POST, data });
export const sandboxGet = (data = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.sandbox.v1.SandboxAPI/Get', method: methods.HTTP_POST, data });
export const sandboxUpdate = (data = {}) => transport({ url: 'twirp/tt.learning.atoms.multicontent.sandbox.v1.SandboxAPI/Update', method: methods.HTTP_POST, data });
export const sandboxIndex = (data = {}) => transport({ url: '/twirp/tt.learning.atoms.multicontent.sandbox.v1.SandboxAPI/Index', method: methods.HTTP_POST, data });

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const createRoute = (data = {}) => transport({ url: '/route', method: methods.HTTP_POST, data });
export const deleteRoute = (id) => transport({ url: `/route/${id}`, method: methods.HTTP_DELETE });
export const getRoute = (id) => transport({ url: `/route/${id}`, method: methods.HTTP_GET });
export const getUpload = ({ data = {}, ...options } = {}) => transport({ url: '/upload', method: methods.HTTP_GET, data, ...options });
export const indexRoute = (payload = {}) => transport({ url: '/route', method: methods.HTTP_GET, payload });
export const oauthSignIn = ({ data = {}, ...options } = {}) => transport({ url: '/oauth/signin', method: methods.HTTP_POST, data, ...options });
export const oauthSignInV2 = ({ data = {}, ...options } = {}) => transport({ url: '/v2/oauth/signin', method: methods.HTTP_POST, data, ...options });
export const pushSend = (data = {}) => transport({ url: '/push', method: methods.HTTP_POST, data });
export const pushSendSkill = (data = {}) => transport({ url: '/push/skill', method: methods.HTTP_POST, data });
export const updateRoute = (data = {}, id) => transport({ url: `/route/${id}`, method: methods.HTTP_PUT, data });

import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class AccountFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const entity = new this.entities.Account({
      id,
      name: dto.name,
      active: dto.active,
    });

    return entity;
  }
}

export default AccountFactory;

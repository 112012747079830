import * as Account from './services/account';
import * as Assessment from './services/assessment';
import * as Authentication from './services/authentication';
import * as CompetencyMatrix from './services/competency_matrix';
import * as ContentStorage from './services/content-storage';
import * as Engagement from './services/engagement';
import * as ExpertAssessment from './services/expert_assessment';
import * as Gogha from './services/gogha';
import * as Invitations from './services/invitations';
import * as License from './services/license';
import * as LxpContentStorage from './services/lxp-content-storage';
import * as Meta from './services/meta';
import * as MultiLevelTest from './services/multi-level-test';
import * as Orgstructure from './services/orgstructure';
import * as Sysadmin from './services/sysadmin';
import * as Talent from './services/talent';
import * as TtCourse from './services/tt-course';
import * as MultiContent from './services/multi_content';
import * as Index from './services/index';
import * as Catalogue from './services/catalogue';
import * as FileToStream from './services/file-to-stream';
import * as Notification from './services/notification';
import * as FeaturesApi from './services/features';

// TODO: этот сервис грузит контент в s3 "Нетологии"
import * as CloudStorage from './services/content-storage/cloud-storage';

import AccessToken from './common/AccessToken';
import GSIToken from './common/GSIToken';
import KeycloakToken from './common/KeycloakToken';
import KeycloakAuth from './common/KeycloakAuth';

export {
  Account,
  Assessment,
  Authentication,
  CompetencyMatrix,
  ContentStorage,
  Engagement,
  ExpertAssessment,
  Gogha,
  Invitations,
  License,
  LxpContentStorage,
  Meta,
  MultiLevelTest,
  Orgstructure,
  Sysadmin,
  Talent,
  TtCourse,
  CloudStorage,
  AccessToken,
  GSIToken,
  MultiContent,
  Index,
  Catalogue,
  FileToStream,
  Notification,
  FeaturesApi,
  KeycloakToken,
  KeycloakAuth,
};

// TODO: конфиги в <PACKAGE_ROOT>
export default function API() {
  return {
    Account,
    Assessment,
    Authentication,
    CompetencyMatrix,
    ContentStorage,
    Engagement,
    ExpertAssessment,
    Gogha,
    Invitations,
    License,
    LxpContentStorage,
    Meta,
    MultiLevelTest,
    Orgstructure,
    Sysadmin,
    Talent,
    TtCourse,
    MultiContent,
    CloudStorage,

    AccessToken,
    GSIToken,

    Index,
    Catalogue,

    FileToStream,

    Notification,
    FeaturesApi,

    KeycloakToken,
    KeycloakAuth,
  };
}

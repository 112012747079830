import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class AssessmentService extends BaseService {
  async createAssessment(createAssessmentDTO) {
    const group = this.factories.group.create(createAssessmentDTO);
    const errors = [];

    if (group.id.startsWith('GENERATED_')) {
      await this.repositories.group.add(group);
    }

    // IMPORTANT: написано намеренно на синхронный манер (сущности нужно создавать последовательно)
    // eslint-disable-next-line
    for (const createProcedureDTO of createAssessmentDTO.procedures) {
      const procedure = this.factories.procedure.create(createProcedureDTO, group.id);

      if (procedure.id.startsWith('GENERATED_')) {
        // eslint-disable-next-line
        await this.repositories.procedure.add(procedure);
        // eslint-disable-next-line
        await this.repositories.group.addProcedure(group, procedure);
      }

      group.addProcedure(procedure);

      // eslint-disable-next-line
      for (const createStepDTO of createProcedureDTO.steps) {
        const step = this.factories.step.create(createStepDTO, procedure.id);

        if (step.id.startsWith('GENERATED_')) {
          // eslint-disable-next-line
          await this.repositories.step.add(step);
        }

        procedure.addStep(step);
      }
    }

    return [group, errors];
  }

  async fetchCompanyGroups(dto) {
    const { companyId, page, limit } = dto;
    const response = await this.repositories.group.getAllByCompany(companyId, page, limit);
    const groups = response.groups.map((g) => {
      const group = this.factories.group.create(g);
      const procedures = g.procedures.map((p) => this.factories.procedure.create(p, group.id));

      group.addProcedures(procedures);

      return group;
    });
    const errors = [];

    return [{ groups, pagination: response.pagination }, errors];
  }

  async fetchGroup(dto) {
    const { groupId } = dto;
    const response = await this.repositories.group.get(groupId);
    const group = this.factories.group.create(response.group);
    const errors = [];

    return [group, errors];
  }

  async fetchGroupProcedures(dto) {
    const { groupId, page, limit } = dto;
    const response = await this.repositories.group.getProcedures(groupId, page, limit);
    const procedures = response.procedures.map((p) => {
      const procedure = this.factories.procedure.create(p, groupId);
      const steps = p.steps.map((s) => this.factories.step.create(s));

      procedure.addSteps(steps);

      return procedure;
    });
    const errors = [];

    return [{ procedures, pagination: response.pagination }, errors];
  }

  async getProcedure(dto) {
    const response = await this.repositories.procedure.get(dto.id);
    const procedure = this.factories.procedure.create(response, dto.groupId);
    const errors = [];
    return [procedure, errors];
  }

  async fetchProcedureSteps(dto) {
    const { procedureId, page, limit } = dto;
    const response = await this.repositories.step.index(procedureId, page, limit);
    const steps = response.steps.map((p) => {
      const step = this.factories.step.create(p, procedureId);
      return step;
    });
    const errors = [];
    return [{ steps, pagination: response.pagination }, errors];
  }

  async createProcedure(dto, groupId) {
    const procedure = this.factories.procedure.create(dto, groupId);
    const errors = [];
    if (procedure.id.startsWith('GENERATED_')) {
      await this.repositories.procedure.add(procedure);
      await this.repositories.group.addProcedure({ id: groupId }, procedure);
    }
    return [procedure, errors];
  }

  async updateProcedure(dto, groupId) {
    const procedure = this.factories.procedure.create(dto, groupId);
    const errors = [];
    await this.repositories.procedure.update(procedure);
    return [procedure, errors];
  }

  async archiveProcedure(dto, groupId) {
    const procedure = this.factories.procedure.create(dto, groupId);
    const [result, error] = await this.repositories.procedure.archive(procedure);
    return [result, [error]];
  }

  async createStep(dto, procedureId) {
    const step = this.factories.step.create(dto, procedureId);
    const errors = [];
    if (step.id.startsWith('GENERATED_')) {
      await this.repositories.step.add(step);
    }
    return [step, errors];
  }

  async getStep(dto) {
    const response = await this.repositories.step.get(dto.id, dto.procedureId);
    const step = this.factories.step.create(response, dto.procedureId);
    const errors = [];
    return [step, errors];
  }

  async updateStep(dto) {
    const step = await this.factories.step.create(dto, dto.procedureId);
    const errors = [];
    await this.repositories.step.update(step);
    return [step, errors];
  }
}

export default AssessmentService;

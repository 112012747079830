/* eslint-disable max-len, vue/max-len */

export const R_LEARNING_HOME = 'learning.home';

/** TALENT */
export const R_TALENT_DASHBOARD = 'talent.dashboard';
export const R_TALENT_TESTS = 'talent.tests';
export const R_TALENT_TEST_CREATE = 'talent.test.create';
export const R_TALENT_TEST = 'talent.test.test-id';
export const R_TALENT_TEST_EDIT = 'talent.test.test-id.edit';
export const R_TALENT_TEST_TOPICS = 'talent.test.test-id.topics';
export const R_TALENT_TEST_TOPIC = 'talent.test.test-id.topics.topic-key';
export const R_TALENT_TEST_TOPIC_CREATE = 'talent.test.test-id.topics.create';
export const R_TALENT_TEST_TOPIC_EDIT = 'talent.test.test-id.topics.topic-key.edit';
export const R_TALENT_TEST_TOPIC_QUESTIONS = 'talent.test.test-id.topics.topic-key.questions';
export const R_TALENT_TEST_TOPIC_QUESTION = 'talent.test.test-id.topics.topic-key.questions.question-id';
export const R_TALENT_TEST_TOPIC_QUESTION_CREATE = 'talent.test.test-id.topics.topic-key.questions.create';
export const R_TALENT_TEST_TOPIC_QUESTION_EDIT = 'talent.test.test-id.topics.topic-key.questions.question-id.edit';
export const R_TALENT_TEST_TOPIC_QUESTION_ANSWERS = 'talent.test.test-id.topics.topic-key.questions.question-id.answers';
export const R_TALENT_TEST_TOPIC_QUESTION_ANSWER = 'talent.test.test-id.topics.topic-key.questions.question-id.answers.answer-id';
export const R_TALENT_TEST_TOPIC_QUESTION_ANSWER_CREATE = 'talent.test.test-id.topics.topic-key.questions.question-id.answers.create';
export const R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT = 'talent.test.test-id.topics.topic-key.questions.question-id.answers.answer-id.edit';
export const R_TALENT_QUESTIONS_FILE_UPLOAD = 'talent.test.test-id.questions-file-upload';
export const R_TALENT_SMS = 'talent.sms';
export const R_TALENT_SMS_CREATE = 'talent.sms.create';
export const R_TALENT_SMS_ID_EDIT = 'talent.sms.company-id.edit';
export const R_TALENT_TOS_LIST = 'talent.tos-list';
export const R_TALENT_TOS = 'talent.tos.company-id';
export const R_TALENT_TOS_CREATE = 'talent.tos.create';
export const R_TALENT_TOS_EDIT = 'talent.tos.company-id.edit';

/** EXPERT-ASSESSMENTS */
export const R_EXPERT_ASSESSMENTS_LIST = 'expert-assessment';
export const R_EXPERT_ASSESSMENTS_CREATE = 'expert-assessment.create';
export const R_EXPERT_ASSESSMENTS_EDIT = 'expert-assessment.edit';
export const R_EXPERT_ASSESSMENTS_VIEW = 'expert-assessment.view';
export const R_EXPERT_ASSESSMENTS_INDICATORS_INDEX = 'expert-assessment.indicators.index';
export const R_EXPERT_ASSESSMENTS_INDICATORS_CREATE = 'expert-assessment.indicators.create';
export const R_EXPERT_ASSESSMENTS_INDICATORS_VIEW = 'expert-assessment.indicators.view';
export const R_EXPERT_ASSESSMENTS_INDICATORS_EDIT = 'expert-assessment.indicators.edit';

/** MULTI-LEVEL-TEST */
export const R_MULTI_LEVEL_TEST_TESTS_INDEX = 'multi-level-test.tests.index';
export const R_MULTI_LEVEL_TEST_TESTS_CREATE = 'multi-level-test.tests.create';
export const R_MULTI_LEVEL_TEST_TESTS_VIEW = 'multi-level-test.tests.view';
export const R_MULTI_LEVEL_TEST_TESTS_EDIT = 'multi-level-test.tests.edit';
export const R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX = 'multi-level-test.tests.topics.index';
export const R_MULTI_LEVEL_TEST_TESTS_TOPICS_CREATE = 'multi-level-test.tests.topics.create';
export const R_MULTI_LEVEL_TEST_TESTS_TOPICS_EDIT = 'multi-level-test.tests.topics.edit';
export const R_MULTI_LEVEL_TEST_TESTS_TOPICS_VIEW = 'multi-level-test.tests.topics.view';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_INDEX = 'multi-level-test.tests.levels.index';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_CREATE = 'multi-level-test.tests.levels.create';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_VIEW = 'multi-level-test.tests.levels.view';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_EDIT = 'multi-level-test.tests.levels.edit';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_INDEX = 'multi-level-test.tests.levels.questions.index';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CREATE = 'multi-level-test.tests.levels.questions.create';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_VIEW = 'multi-level-test.tests.levels.questions.view';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_EDIT = 'multi-level-test.tests.levels.questions.edit';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_INDEX = 'multi-level-test.tests.levels.questions.choices.index';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_CREATE = 'multi-level-test.tests.levels.questions.choices.create';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_EDIT = 'multi-level-test.tests.levels.questions.choices.edit';
export const R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_VIEW = 'multi-level-test.tests.levels.questions.choices.view';

/** CONTENT STORAGE */
export const R_CONTENT_STORAGE_DASHBOARD = 'content-storage.dashboard';
export const R_CONTENT_STORAGE_COURSES = 'content-storage.courses';
export const R_CONTENT_STORAGE_CONTENTS = 'content-storage.contents';
export const R_CONTENT_STORAGE_CONTENT = 'content-storage.contents.content-id';
export const R_CONTENT_STORAGE_CONTENT_EDIT = 'content-storage.contents.content-id.edit';
export const R_CONTENT_STORAGE_CONTENT_PROVIDERS = 'content-storage.content-providers';
export const R_CONTENT_STORAGE_CONTENT_PROVIDER = 'content-storage.content-providers.content-provider-id';
export const R_CONTENT_STORAGE_COURSE_CREATE = 'content-storage.course.create';
export const R_CONTENT_STORAGE_COURSE_EDIT = 'content-storage.course.edit';
export const R_CONTENT_STORAGE_COURSE_TOPICS_CONTENT_EDIT = 'content-storage.course.topics.topic-key.edit';
export const R_CONTENT_STORAGE_COURSE_TOPICS_EDIT = 'content-storage.course.topics.edit';
export const R_CONTENT_STORAGE_COURSE_TOPICS_CREATE = 'content-storage.course.topics.create';
export const R_CONTENT_STORAGE_COPYRIGHT = 'content-storage.copyright';
export const R_CONTENT_STORAGE_COPYRIGHT_CREATE = 'content-storage.copyright.create';
export const R_CONTENT_STORAGE_COPYRIGHT_VIEW = 'content-storage.copyright.view';

/** LEARNING COURSE v2 */
export const R_LEARNING_COURSE_V2_CREATE = 'learning.course.v2.create'; // TODO: delete?
export const R_LEARNING_COURSE_V2_CONTENT_CREATE = 'learning.course.v2.content.create';
export const R_LEARNING_COURSE_V2_CONTENT_HTML_EDIT = 'learning.course.v2.content.html.edit';
export const R_LEARNING_COURSE_V2_CONTENT_VIDEO_EDIT = 'learning.course.v2.content.video.edit';
export const R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT = 'learning.course.v2.content.copyright';
export const R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT_EDIT = 'learning.course.v2.content.copyright.edit';
export const R_LEARNING_COURSE_V2_TOPICS_CREATE = 'learning.course.v2.topics.create';
export const R_LEARNING_COURSE_V2_TOPICS_EDIT = 'learning.course.v2.topics.edit';
export const R_LEARNING_COURSE_V2_COURSES_CREATE = 'learning.course.v2.courses.create';
export const R_LEARNING_COURSE_V2_COURSES_EDIT = 'learning.course.v2.courses.edit.courseId';

/** ASSESSMENTS */
export const R_LEARNING_ASSESSMENTS_LIST = 'learning.assessments';
export const R_LEARNING_ASSESSMENT_GROUPS = 'learning.assessment.groups';
export const R_LEARNING_ASSESSMENTS_CREATE = 'learning.assessments.create';
export const R_LEARNING_ASSESSMENT_GROUPS_VIEW = 'learning.assessment.groups.view';
export const R_LEARNING_ASSESSMENT_PROCEDURE_CREATE = 'learning.assessment.procedure.create';
export const R_LEARNING_ASSESSMENT_PROCEDURE_VIEW = 'learning.assessment.procedure.view';
export const R_LEARNING_ASSESSMENT_PROCEDURE_EDIT = 'learning.assessment.procedure.edit';
export const R_LEARNING_ASSESSMENT_PROCEDURE_ARCHIVE = 'learning.assessment.procedure.archive';
export const R_LEARNING_ASSESSMENT_STEP_CREATE = 'learning.assessment.step.create';
export const R_LEARNING_ASSESSMENT_STEP_VIEW = 'learning.assessment.step.view';
export const R_LEARNING_ASSESSMENT_STEP_EDIT = 'learning.assessment.step.edit';

/** HISTORYDATA */
export const R_LEARNING_HISTORYDATA_UPLOAD = 'learning.historydata.upload';

/** CONTENT-PROVIDER */
export const R_LEARNING_CONTENT_PROVIDER = 'learning.content.providers';
export const R_LEARNING_CONTENT_PROVIDER_EDIT = 'learning.content-provider.edit';

import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class GroupFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const group = new this.entities.Group({
      id,
      name: dto.name,
      companyId: dto.companyId,
      teamId: dto.teamId,
      userId: dto.userId,
      procedures: [],
    });

    return group;
  }
}

export default GroupFactory;

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const accountAddUser = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/AddUser', method: methods.HTTP_POST, data });
export const accountRemoveUser = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/RemoveUser', method: methods.HTTP_POST, data });
export const activateAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Activate', method: methods.HTTP_POST, data });
export const activateCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Activate', method: methods.HTTP_POST, data });
export const getCompanyUsers = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/GetCompanyUsers', method: methods.HTTP_POST, data });
export const companyAddUser = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/AddUser', method: methods.HTTP_POST, data });
export const companyRemoveUser = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/RemoveUser', method: methods.HTTP_POST, data });
export const createAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Create', method: methods.HTTP_POST, data });
export const createCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Create', method: methods.HTTP_POST, data });
export const deactivateAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Deactivate', method: methods.HTTP_POST, data });
export const deactivateCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Deactivate', method: methods.HTTP_POST, data });
export const getAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Get', method: methods.HTTP_POST, data });
export const getCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Get', method: methods.HTTP_POST, data });
export const indexAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Index', method: methods.HTTP_POST, data });
export const indexCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Index', method: methods.HTTP_POST, data });
export const updateAccount = (data = {}) => transport({ url: '/twirp/tt.core.accounts.accounts.v1.AccountsAPI/Update', method: methods.HTTP_POST, data });
export const updateCompany = (data = {}) => transport({ url: '/twirp/tt.core.accounts.companies.v1.CompaniesAPI/Update', method: methods.HTTP_POST, data });
export const searchCompanies = (data = {}) => transport({ url: '/twirp/tt.m9t.accounts.SearchAPI/SearchCompanies', method: methods.HTTP_POST, data });
export const GetCompaniesBatch = (data = {}) => transport({ url: '/twirp/tt.m9t.accounts.CompaniesAPI/GetCompaniesBatch', method: methods.HTTP_POST, data });

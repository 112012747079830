import AssessmentService from './Assessment.service';

import GroupEntity from './Group.entity';
import GroupFactory from './Group.factory';
import GroupRepository from './Group.http.repository';

import ProcedureEntity from './Procedure.entity';
import ProcedureFactory from './Procedure.factory';
import ProcedureRepository from './Procedure.http.repository';

import StepEntity from './Step.entity';
import StepFactory from './Step.factory';
import StepRepository from './Step.http.repository';

function App() {
  const container = {
    repositories: {
      group: new GroupRepository(),
      procedure: new ProcedureRepository(),
      step: new StepRepository(),
    },
    entities: {
      Group: GroupEntity,
      Procedure: ProcedureEntity,
      Step: StepEntity,
    },
    factories: {},
    services: {},
  };

  container.factories = {
    group: new GroupFactory(container),
    procedure: new ProcedureFactory(container),
    step: new StepFactory(container),
  };

  container.services = {
    assessment: new AssessmentService(container),
  };

  return container;
}

export default App;

import { CORE_ROUTES_NAMES } from '@front.backoffice/core';
import { ENGAGEMENT_ROUTES_NAMES } from '@front.backoffice/engagement';
import { LEARNING_ROUTES_NAMES } from '@front.backoffice/learning';
import { GOALS_ROUTES_NAMES } from '@front.backoffice/goals';
import { ONBOARDING_ROUTES_NAMES } from '@front.backoffice/onboarding';

export const R_APP = 'app';
export const R_ERROR_401 = 'error.401';
export const R_ERROR_403 = 'error.403';
export const R_ERROR_404 = 'error.404';
export const R_ERROR_500 = 'error.500';

const APP_ROUTES_NAMES = {
  R_APP,
  R_ERROR_401,
  R_ERROR_403,
  R_ERROR_404,
  R_ERROR_500,
};

export {
  CORE_ROUTES_NAMES,
  ENGAGEMENT_ROUTES_NAMES,
  LEARNING_ROUTES_NAMES,
  GOALS_ROUTES_NAMES,
  ONBOARDING_ROUTES_NAMES,
  APP_ROUTES_NAMES,
};

import Keycloak from 'keycloak-js';

class KeycloakAuth {
  static CONFIG = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  };

  static INIT_OPTIONS = {
    // если параметр активен, то keycloak сразу отправится на форму логина
    // а так как сейчас используется кнопка для входа и отдельный вызов login(), то этот параметр лишний
    // onLoad: 'login-required',
    // без указания этого параметра будет подставляться адрес страницы на которой был вызван init()
    // redirectUri: process.env.VUE_APP_BACKOFFICE_ADDRESS,
    flow: 'implicit',
    scope: 'openid profile',
  };

  constructor(config = KeycloakAuth.CONFIG, initOptions = KeycloakAuth.INIT_OPTIONS) {
    this.config = config;
    this.initOptions = initOptions;
  }

  create() {
    this.keycloak = new Keycloak(this.config);
  }

  init() {
    return this.keycloak.init(this.initOptions);
  }

  login() {
    return this.keycloak.login();
  }

  logout() {
    this.keycloak.logout({
      redirectUri: process.env.VUE_APP_BACKOFFICE_ADDRESS,
    });
  }
}

export default new KeycloakAuth();

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const createContent = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/Create', method: methods.HTTP_POST, data });
export const createCourse = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.courses.v1.CoursesAPI/Create', method: methods.HTTP_POST, data });
export const createProvider = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/Create', method: methods.HTTP_POST, data });
export const deleteProvider = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/Delete', method: methods.HTTP_POST, data });
export const fireUploadHook = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/VideoSign', method: methods.HTTP_POST, data });
export const getContent = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/Get', method: methods.HTTP_POST, data });
export const getCourse = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.courses.v1.CoursesAPI/Get', method: methods.HTTP_POST, data });
export const getProvider = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/Get', method: methods.HTTP_POST, data });
export const indexContent = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/Index', method: methods.HTTP_POST, data });
export const indexCourse = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.courses.v1.CoursesAPI/Index', method: methods.HTTP_POST, data });
export const indexProvider = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/Index', method: methods.HTTP_POST, data });
export const signVideo = (data = {}) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/VideoSign', method: methods.HTTP_POST, data });
export const updateContent = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/Update', method: methods.HTTP_POST, data });
export const updateContentImage = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.content.v1.ContentAPI/UpdateImage', method: methods.HTTP_POST, data });
export const updateCourse = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.courses.v1.CoursesAPI/Update', method: methods.HTTP_POST, data });
export const updateCourseImage = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.courses.v1.CoursesAPI/UpdateImage', method: methods.HTTP_POST, data });
export const updateProvider = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/Update', method: methods.HTTP_POST, data });
export const updateProviderImage = (data) => transport({ url: '/twirp/tt.lxp.contentstorage.providers.v1.ProvidersAPI/UpdateImage', method: methods.HTTP_POST, data });

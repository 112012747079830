import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';

class AccessToken {
  static DEFAULT_OPTIONS = {
    name: 'fnd_access_token',
  };

  constructor(options = AccessToken.DEFAULT_OPTIONS) {
    this.cookies = Cookies;
    this.jwt = jwt;
    this.options = options;
  }

  set(value) {
    const { name } = this.options;
    const decoded = this.jwt.decode(value);
    const params = {
      domain: new URL(window.location).hostname.replace(/.*(talenttechlab\.(com|org))$/, '.$1'),
      expires: new Date(decoded.exp * 1000),
    };

    this.cookies.set(name, value, params);
  }

  get() {
    const { name } = this.options;

    return this.cookies.get(name);
  }

  remove() {
    const { name } = this.options;
    const params = {
      domain: new URL(window.location).hostname.replace(/.*(talenttechlab\.(com|org))$/, '.$1'),
    };

    return this.cookies.remove(name, params);
  }

  isExpired() {
    const value = this.get();
    const decoded = jwt.decode(value);

    if (decoded === null) return true;

    // Note: нужно умножить на 1000, т.к. JS требует миллисекунды, а в jwt секунды. (^_^)
    return new Date(decoded?.exp * 1000) < new Date();
  }
}

export default new AccessToken();

// Note: при создании и обновлении шага - нельзя задать order, wtf?
// eslint-disable-next-line
// @see: https://github.com/severgroup-tt/assessment/blob/0d6dff38f4d7def32bba9dd042c4ac27b0d7f7e6/rpc/tt/learning/procedures/v1/procedures_api.proto#L108
const DEFAULT_STEP = {
  id: null,
  name: null,
  description: null,
  order: null,
  type: null,
  // IMPORTANT: в API поле defaultLevel -> default
  defaultLevel: null,
  duration: null,
  parameters: null,
  instrumentId: null,
  procedureId: null,
  hasResult: null,
};

class StepEntity {
  constructor(step = DEFAULT_STEP, procedureId) {
    this.id = step.id;
    this.name = step.name;
    this.description = step.description;
    this.order = step.order;
    this.type = step.type;
    this.defaultLevel = step.defaultLevel;
    this.duration = step.duration;
    this.parameters = step.parameters;
    this.instrumentId = step.instrumentId;
    this.procedureId = procedureId;
    this.hasResult = step.hasResult;
  }
}

export default StepEntity;

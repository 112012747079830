/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const contentItemArchive = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/Archive', method: methods.HTTP_POST, data });
export const contentItemCreate = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/Create', method: methods.HTTP_POST, data });
export const contentItemGet = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/Get', method: methods.HTTP_POST, data });
export const contentItemOrphaned = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/Orphaned', method: methods.HTTP_POST, data });
export const contentItemUpdate = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/Update', method: methods.HTTP_POST, data });
export const contentItemVideoUploaded = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.items.v1.ItemsAPI/VideoUploaded', method: methods.HTTP_POST, data });
export const copyrightCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.contentstorage.copyrights.v1.CopyrightsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const copyrightDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.contentstorage.copyrights.v1.CopyrightsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const copyrightGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.contentstorage.copyrights.v1.CopyrightsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const copyrightIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.contentstorage.copyrights.v1.CopyrightsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const courseItemCreate = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.courses.v1.CoursesAPI/Edit', method: methods.HTTP_POST, data });
export const courseItemEdit = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.courses.v1.CoursesAPI/Edit', method: methods.HTTP_POST, data });
// IMPORTANT: в ручке Courses#Get если не запросить топики  возвращается весь контент под топиком all
// @see: https://burning-heart.atlassian.net/wiki/spaces/TT/pages/1171882001#%D0%A2%D0%BE%D0%BF%D0%B8%D0%BA%D0%B8
// @see: https://burning-heart.atlassian.net/browse/MNB-2090?focusedCommentId=58433
export const courseItemGet = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.courses.v1.CoursesAPI/Get', method: methods.HTTP_POST, data });
export const courseItemIndex = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.courses.v1.CoursesAPI/Index', method: methods.HTTP_POST, data });
export const courseItemLabels = (data = {}) => transport({ url: '/twirp/tt.core.contentstorage.courses.v1.CoursesAPI/Labels', method: methods.HTTP_POST, data });

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const TemplatesCreate = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Create', data, ...options });
export const TemplatesDelete = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Delete', data, ...options });
export const TemplatesGet = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Get', data, ...options });
export const TemplatesIndex = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Index', data, ...options });
export const TemplatesSet = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Set', data, ...options });
export const TemplatesUpdate = (data = {}, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.notification.templates.v1.TemplatesAPI/Update', data, ...options });

/* eslint-disable max-len, vue/max-len */
import { AppLearningAsideLeft as AppAsideLeft, AppNav, HolyGrailLayout } from '@front.backoffice/shared';
import * as Names from './app.routes.names';

const MODULE_PREFIX = '/app/learning';

export default [
  {
    path: MODULE_PREFIX,
    component: HolyGrailLayout,
    children: [
      {
        path: '',
        name: Names.R_LEARNING_HOME,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/Home.vue'),
        },
      },
      {
        path: 'content-storage',
        name: Names.R_CONTENT_STORAGE_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/Dashboard.vue'),
        },
      },
      {
        path: 'content-storage/courses',
        name: Names.R_CONTENT_STORAGE_COURSES,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseSearch.vue'),
        },
      },
      {
        path: 'content-storage/courses/edit',
        name: Names.R_CONTENT_STORAGE_COURSE_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseEdit.vue'),
        },
      },
      {
        path: 'content-storage/courses/topics/edit',
        name: Names.R_CONTENT_STORAGE_COURSE_TOPICS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseTopicsEdit.vue'),
        },
      },
      {
        path: 'content-storage/courses/topics/create',
        name: Names.R_CONTENT_STORAGE_COURSE_TOPICS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseTopicCreate.vue'),
        },
      },
      {
        path: 'content-storage/courses/topics/:topicKey/edit',
        name: Names.R_CONTENT_STORAGE_COURSE_TOPICS_CONTENT_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseContentAdd.vue'),
        },
      },
      {
        path: 'content-storage/courses/create',
        name: Names.R_CONTENT_STORAGE_COURSE_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/courses/CourseCreate.vue'),
        },
      },
      {
        path: 'content-storage/content',
        name: Names.R_CONTENT_STORAGE_CONTENTS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/content/ContentStorage.vue'),
        },
      },
      {
        path: 'content-storage/content/:contentId/edit',
        name: Names.R_CONTENT_STORAGE_CONTENT_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/content/ContentEdit.vue'),
        },
      },
      {
        path: 'content-storage/copyright',
        name: Names.R_CONTENT_STORAGE_COPYRIGHT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/copyright/CopyrightList.vue'),
        },
      },
      {
        path: 'content-storage/copyright/create',
        name: Names.R_CONTENT_STORAGE_COPYRIGHT_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/copyright/CopyrightCreate.vue'),
        },
      },
      {
        path: 'content-storage/copyright/:copyrightId',
        name: Names.R_CONTENT_STORAGE_COPYRIGHT_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/contentstorage/copyright/CopyrightView.vue'),
        },
      },
      {
        path: 'expert-assessment',
        name: Names.R_EXPERT_ASSESSMENTS_LIST,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/AssessmentsList.vue'),
        },
      },
      {
        path: 'expert-assessment/create',
        name: Names.R_EXPERT_ASSESSMENTS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/AssessmentCreate.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId/edit',
        name: Names.R_EXPERT_ASSESSMENTS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/AssessmentEdit.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId',
        name: Names.R_EXPERT_ASSESSMENTS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/AssessmentView.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId/indicators',
        name: Names.R_EXPERT_ASSESSMENTS_INDICATORS_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/indicators/IndicatorsList.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId/indicators/create',
        name: Names.R_EXPERT_ASSESSMENTS_INDICATORS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/indicators/IndicatorsCreate.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId/indicators/:indicatorId/view',
        name: Names.R_EXPERT_ASSESSMENTS_INDICATORS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/indicators/IndicatorsView.vue'),
        },
      },
      {
        path: 'expert-assessment/:assessmentId/indicators/:indicatorId/edit',
        name: Names.R_EXPERT_ASSESSMENTS_INDICATORS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/expert-assessment/indicators/IndicatorsEdit.vue'),
        },
      },

      // TODO: fixme routes
      {
        path: 'learning',
        name: Names.R_LEARNING_COURSE_V2_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/CreateCourse.vue'),
        },
      },
      {
        path: 'learning/content',
        name: Names.R_LEARNING_COURSE_V2_CONTENT_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/CreateContent.vue'),
        },
      },
      {
        path: 'learning/content/:contentId/html/edit',
        name: Names.R_LEARNING_COURSE_V2_CONTENT_HTML_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/EditHtmlContent.vue'),
        },
      },
      {
        path: 'learning/content/:contentId/video/edit',
        name: Names.R_LEARNING_COURSE_V2_CONTENT_VIDEO_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/EditVideoContent.vue'),
        },
      },
      {
        path: 'learning/copyright',
        name: Names.R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/CreateCopyright.vue'),
        },
      },
      {
        path: 'learning/copyright/:copyrightId/edit',
        name: Names.R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/EditCopyright.vue'),
        },
      },
      {
        path: 'learning/topics',
        name: Names.R_LEARNING_COURSE_V2_TOPICS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/CreateTopic.vue'),
        },
      },
      {
        path: 'learning/topics/:topicId/edit',
        name: Names.R_LEARNING_COURSE_V2_TOPICS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/EditTopic.vue'),
        },
      },
      {
        path: 'learning/courses',
        name: Names.R_LEARNING_COURSE_V2_COURSES_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/CreateCourse.vue'),
        },
      },
      {
        path: 'learning/courses/:courseId',
        name: Names.R_LEARNING_COURSE_V2_COURSES_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/learning/EditCourse.vue'),
        },
      },
      {
        path: 'multi-level-test/tests',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/tests/MultiLevelTests.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/create',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/tests/MultiLevelTestsCreate.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/tests/MultiLevelTestsView.vue'),
        },
      },
      {
        path: 'multi-level-test/tests/:testId/edit',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/tests/MultiLevelTestsEdit.vue'),
        },
      },
      {
        path: 'multi-level-test/tests/:testId/topics',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/topics/TestTopics.vue'),
        },
      },
      {
        path: 'multi-level-test/tests/:testId/topics/create',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/topics/TestsTopicsCreate.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/edit',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/topics/TestsTopicsEdit.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/view',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/topics/TestsTopicsView.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/levels/TestsLevels.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/create',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/levels/TestsLevelsCreate.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/view',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/levels/TestsLevelsView.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/edit',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/levels/TestsLevelsEdit.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/questions/LevelsQuestions.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/create',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/questions/LevelsQuestionsCreate.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/view',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/questions/LevelsQuestionsView.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/edit',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/questions/LevelsQuestionsEdit.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/choices',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_INDEX,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/choices/QuestionsChoices.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/choices/create',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/choices/QuestionsChoicesCreate.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/choices/:choiceId/view',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/choices/QuestionsChoicesView.vue'),
        },
      },

      {
        path: 'multi-level-test/tests/:testId/topics/:topicId/levels/:levelId/questions/:questionId/choices/:choiceId/edit',
        name: Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/multi-level-test/choices/QuestionsChoicesEdit.vue'),
        },
      },

      {
        path: 'talent',
        name: Names.R_TALENT_DASHBOARD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/Dashboard.vue'),
        },
      },
      {
        path: 'talent/tests',
        name: Names.R_TALENT_TESTS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/TestList.vue'),
        },
      },
      {
        path: 'talent/tests/create',
        name: Names.R_TALENT_TEST_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/TestCreate.vue'),
        },
      },
      {
        path: 'talent/tests/:testId',
        name: Names.R_TALENT_TEST,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/TestView.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/edit',
        name: Names.R_TALENT_TEST_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/TestEdit.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics',
        name: Names.R_TALENT_TEST_TOPICS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/TopicList.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/create',
        name: Names.R_TALENT_TEST_TOPIC_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/TopicCreate.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId',
        name: Names.R_TALENT_TEST_TOPIC,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/TopicView.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/edit',
        name: Names.R_TALENT_TEST_TOPIC_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/TopicEdit.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions',
        name: Names.R_TALENT_TEST_TOPIC_QUESTIONS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/QuestionList.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/create',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/QuestionCreate.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/QuestionView.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId/edit',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/QuestionEdit.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId/answers',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/answers/AnswerList.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId/answers/create',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/answers/AnswerCreate.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId/answers/:answerId',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/answers/AnswerView.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/topics/:topicId/questions/:questionId/answers/:answerId/edit',
        name: Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWER_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/topics/questions/answers/AnswerEdit.vue'),
        },
      },
      {
        path: 'talent/tests/:testId/question-file-upload',
        name: Names.R_TALENT_QUESTIONS_FILE_UPLOAD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/test/TestQuestionsFileUpload.vue'),
        },
      },
      {
        path: 'talent/sms',
        name: Names.R_TALENT_SMS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/sms/SmsList.vue'),
        },
      },
      {
        path: 'talent/sms/create',
        name: Names.R_TALENT_SMS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/sms/SmsCreate.vue'),
        },
      },
      {
        path: 'talent/sms/:companyId/edit',
        name: Names.R_TALENT_SMS_ID_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/sms/SmsConfigEdit.vue'),
        },
      },
      {
        path: 'talent/tos',
        name: Names.R_TALENT_TOS_LIST,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/tos/TosList.vue'),
        },
      },
      {
        path: 'talent/tos/create',
        name: Names.R_TALENT_TOS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/tos/TosCreate.vue'),
        },
      },
      {
        path: 'talent/tos/:companyId',
        name: Names.R_TALENT_TOS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/tos/Tos.vue'),
        },
      },
      {
        path: 'talent/tos/:companyId/edit',
        name: Names.R_TALENT_TOS_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/talent/tos/TosEdit.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups',
        name: Names.R_LEARNING_ASSESSMENT_GROUPS,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/GroupsList.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId',
        name: Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/GroupsView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/create',
        name: Names.R_LEARNING_ASSESSMENT_PROCEDURE_CREATE,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/ProcedureFormView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/:procedureId',
        name: Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/ProcedureView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/:procedureId/edit',
        name: Names.R_LEARNING_ASSESSMENT_PROCEDURE_EDIT,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/ProcedureFormView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/:procedureId/steps/:stepId',
        name: Names.R_LEARNING_ASSESSMENT_STEP_VIEW,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/StepView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/:procedureId/steps/:stepId/edit',
        name: Names.R_LEARNING_ASSESSMENT_STEP_EDIT,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/StepFormView.vue'),
        },
      },
      {
        path: 'accounts/:accountId/companies/:companyId/assessment/groups/:groupId/procedures/:procedureId/steps/create',
        name: Names.R_LEARNING_ASSESSMENT_STEP_CREATE,
        props: { default: true },
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/StepFormView.vue'),
        },
      },
      {
        path: 'assessments',
        name: Names.R_LEARNING_ASSESSMENTS_LIST,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/AssessmentsList.vue'),
        },
      },
      {
        path: 'assessments/create',
        name: Names.R_LEARNING_ASSESSMENTS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/assessment/AssessmentCreate.vue'),
        },
      },
      {
        path: 'historydata/upload',
        name: Names.R_LEARNING_HISTORYDATA_UPLOAD,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/historydata/HistorydataUpload.vue'),
        },
      },

      {
        path: 'content-provider',
        name: Names.R_LEARNING_CONTENT_PROVIDER,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/content-provider/ContentProvider.vue'),
        },
      },
      {
        path: 'content-provider/:id/edit',
        name: Names.R_LEARNING_CONTENT_PROVIDER_EDIT,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/content-provider/ContentProviderEdit.vue'),
        },
      },
    ],
  },
];

/* eslint-disable import/prefer-default-export */
import transport from './transport';
import * as methods from '../../common/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const featuresGet = (data, ...options) => transport({
  url: '/features',
  method: methods.HTTP_GET,
  headers: {
    'UNLEASH-INSTANCEID': process.env.VUE_APP_FF_INSTANCEID,
    'UNLEASH-APPNAME': process.env.NODE_ENV,
  },
  params: data,
  ...options,
});

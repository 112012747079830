/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const indicatorsByAssessment = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.indicators.v1.IndicatorsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const indicatorsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.indicators.v1.IndicatorsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const indicatorsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.indicators.v1.IndicatorsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const indicatorsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.indicators.v1.IndicatorsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const indicatorsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.indicators.v1.IndicatorsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const skillAssessmentCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.skillassessments.v1.SkillassessmentsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const skillAssessmentDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.skillassessments.v1.SkillassessmentsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const skillAssessmentGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.skillassessments.v1.SkillassessmentsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const skillAssessmentIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.skillassessments.v1.SkillassessmentsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const skillAssessmentUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.skillassessments.v1.SkillassessmentsAPI/Update', method: methods.HTTP_POST, data, ...options });

import LicenseService from './License.service';
import LicenseEntity from './License.entity';
import LicenseFactory from './License.factory';
import LicenseRepository from './License.http.repository';

import LicenseAppService from './LicenseApp.service';
import LicenseAppEntity from './LicenseApp.entity';
import LicenseAppFactory from './LicenseApp.factory';
import LicenseAppRepository from './LicenseApp.http.repository';

function App() {
  const container = {
    repositories: {
      license: new LicenseRepository(),
      licenseApp: new LicenseAppRepository(),
    },
    entities: {
      License: LicenseEntity,
      LicenseApp: LicenseAppEntity,
    },
    factories: {},
    services: {},
  };

  container.factories = {
    license: new LicenseFactory(container),
    licenseApp: new LicenseAppFactory(container),
  };

  container.services = {
    license: new LicenseService(container),
    licenseApp: new LicenseAppService(container),
  };

  return container;
}

export default App;

// https://github.com/severgroup-tt/accounts/blob/master/rpc/tt/core/accounts/companies/v1/common.proto#L8

const DEFAULT_COMPANY = {
  id: null,
  name: null,
  active: true,
  accountId: null,
  image: {
    type: 'company',
    name: 'logo',
  },
};

class CompanyEntity {
  constructor(dto = DEFAULT_COMPANY) {
    this.id = dto.id;
    this.name = dto.name;
    this.active = dto.active;
    this.accountId = dto.accountId;
    this.image = dto.image || DEFAULT_COMPANY.image;
  }
}

export default CompanyEntity;

import Router from 'vue-router';
import accessToken from './guards/accessToken';

function createRouter({ routes, baseUrl, ...options }) {
  const router = new Router({
    mode: 'history',
    baseUrl,
    routes,
    ...options,
  });

  router.beforeEach(accessToken);

  return router;
}

export default createRouter;

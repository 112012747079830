const DEFAULT_TEAM = {
  id: null,
  name: null,
  displayName: null,
  teamType: null,
  companyId: null,
  externalId: null,
  parent: null,
  // Note: custom field
  path: null,
  parentId: null,
};

// TODO: root team?
// TODO: child team?
class TeamEntity {
  constructor(dto = DEFAULT_TEAM) {
    this.id = dto.id;
    this.name = dto.name;
    this.displayName = dto.displayName;
    this.teamType = dto.teamType;
    this.companyId = dto.companyId;
    this.externalId = dto.externalId;
    this.parent = dto.parent;
    this.parentId = dto.parentId;
    this.path = dto.path;
  }
}

export default TeamEntity;

import * as API from '@front.backoffice/api';

import { BaseRepository } from '../common/BaseRepository';

class CompanyHttpRepository extends BaseRepository {
  async create(company) {
    const data = {
      company: {
        name: company.name,
        accountId: company.accountId,
      },
    };

    const { id } = await API.Account.createCompany(data);
    Object.assign(company, { id });

    this.collection.push(company);

    return company;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadImage(company, image) {
    if (image) {
      const uuid = company.id;
      const data = new FormData();
      data.append('image', image);
      await API.Gogha.UploadImage(company.image.type, uuid, company.image.name, data);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async get(id) {
    const { company } = await API.Account.getCompany({ id });
    return company;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsers(id) {
    const { userIds } = await API.Account.getCompanyUsers({ companyId: id });
    return userIds;
  }

  // eslint-disable-next-line class-methods-use-this
  async activate(id) {
    await API.Account.activateCompany({ id });
    return id;
  }

  // eslint-disable-next-line class-methods-use-this
  async deactivate(id) {
    await API.Account.deactivateCompany({ id });
    return id;
  }
}

export default CompanyHttpRepository;

import { render, staticRenderFns } from "./BadgeMenu.vue?vue&type=template&id=057101c5&scoped=true&"
import script from "./BadgeMenu.vue?vue&type=script&lang=js&"
export * from "./BadgeMenu.vue?vue&type=script&lang=js&"
import style0 from "./BadgeMenu.vue?vue&type=style&index=0&id=057101c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057101c5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
installComponents(component, {VBadge})

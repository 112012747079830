<template>
  <VToolbar rounded>
    <VSlideGroup
      show-arrows
    >
      <VSlideItem
        v-for="item in items"
        :key="item.id"
      >
        <VMenu
          offset-y
          :min-width="200"
        >
          <template #activator="{ on, attrs, value }">
            <TTBtn
              class="mr-3"
              color="tt-ghost"
              plain
              v-bind="attrs"
              v-on="on"
            >
              {{ item.title }}

              <VIcon
                right
                v-text="value === false ? 'fal fa-caret-down' : 'fal fa-caret-up'"
              />
            </TTBtn>
          </template>

          <VList nav>
            <VListItemGroup>
              <VListItem
                v-for="child in item.children"
                :key="child.id"
                exact
                :to="child.to"
              >
                <VListItemTitle>
                  <VIcon
                    class="pr-3"
                    small
                    v-text="child.icon"
                  />

                  {{ child.title }}
                </VListItemTitle>
              </VListItem>
            </VListItemGroup>
          </VList>
        </VMenu>
      </VSlideItem>
    </VSlideGroup>
  </VToolbar>
</template>

<script>
export default {
  name: 'ModuleNav',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

/* eslint-disable import/prefer-default-export */
import { create } from 'axios';
import toCamelCaseResponseTransformer from './transformers/toCamelCaseResponseTransformer';
import toSnakeCaseRequestTransformer from './transformers/toSnakeCaseRequestTransformer';
import AccessToken from './AccessToken';

/*
 * @see: https://github.com/axios/axios#request-config
 */
function createAxiosTransport({ baseURL = 'http://localhost:3000/', ...options } = {}) {
  const transport = create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    responseEncoding: 'utf8',
    ...options,
  });

  // IMPORTANT: стандартный трансформер запросов конвертит данные в строки,
  // поэтому сперва используй наш трансформер запросов для массивов и объектов
  transport.defaults.transformRequest.unshift(toSnakeCaseRequestTransformer);
  transport.defaults.transformResponse.push(toCamelCaseResponseTransformer);

  // IMPORTANT: порядок для колбеков ОБРАТНЫЙ !!!
  transport.interceptors.request.use(
    (conf) => {
      const token = AccessToken.get();

      if (token) Object.assign(conf.headers, { Authorization: token });

      return conf;
    },
    (error) => Promise.reject(error),
  );

  // HACK: у axios есть баг, интерсепторы запросов идут в обратном порядке, в
  // отличии от интерсепторов ответов, поэтому мутируем порядок с помощью
  // reverseInterceptorsOrder() или reverse()
  // see:
  //  * https://github.com/axios/axios/issues/1663
  //  * https://github.com/axios/axios/pull/2085
  if (typeof transport.reverseInterceptorsOrder === 'function') {
    transport.reverseInterceptorsOrder();
  } else {
    transport.interceptors.request.handlers.reverse();
  }

  // IMPORTANT: порядок для коллбеков ПРЯМОЙ !!!
  transport.interceptors.response.use(
    (response) => response,
    (error) => {
      // FIXME: использовать роутер?
      if ([401, 403].includes(error.response?.status)) {
        window.location.href = `/auth/sign-in?redirect=${window.location.pathname}`;
      }

      return Promise.reject(error);
    },
  );

  return transport;
}

export default createAxiosTransport;

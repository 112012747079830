<template>
  <TTModuleMenu
    :items-tree="menuItems"
    hide-sign-out
  />
</template>

<script>
export default {
  name: 'AppLearningAsideLeft',

  inject: ['Names'],

  computed: {
    menuItems() {
      const items = [
        [
          {
            title: 'Главная',
            to: { name: this.Names.R_LEARNING_HOME },
            icon: 'fal fa-columns',
            exact: true,
          },
          {
            title: 'Курсы',
            icon: 'fal fa-clipboard-list fa-fw',
            children: [
              {
                title: 'Курсы',
                icon: 'far fa-comment',
                to: { name: this.Names.R_LEARNING_COURSE_V2_COURSES_CREATE },
                exact: true,
              },
              {
                title: 'Топики',
                icon: 'fas fa-minus',
                to: { name: this.Names.R_LEARNING_COURSE_V2_TOPICS_CREATE },
                exact: true,
              },
              {
                title: 'Контент',
                icon: 'fas fa-align-justify',
                to: { name: this.Names.R_LEARNING_COURSE_V2_CONTENT_CREATE },
                exact: true,
              },
              {
                title: 'Копирайты',
                icon: 'far fa-copyright',
                to: { name: this.Names.R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT },
                exact: true,
              },
              {
                title: 'Контент-провайдеры',
                icon: 'fal fa-book-open fa-fw',
                to: { name: this.Names.R_LEARNING_CONTENT_PROVIDER },
                exact: true,
              },
            ],
          },
          {
            icon: 'fal fa-dot-circle fa-fw',
            title: 'Оценка',
            to: { name: this.Names.R_TALENT_DASHBOARD },
          },
          {
            icon: 'fal fa-folder-tree fa-fw',
            title: 'Контент',
            children: [
              {
                icon: 'fal fa-search',
                title: 'Поиск курсов',
                to: { name: this.Names.R_CONTENT_STORAGE_COURSES },
                exact: true,
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать курс',
                to: { name: this.Names.R_CONTENT_STORAGE_COURSE_CREATE },
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать копирайт',
                to: { name: this.Names.R_CONTENT_STORAGE_COPYRIGHT_CREATE },
                exact: true,
              },
              {
                icon: 'fal fa-list',
                title: 'Список контента',
                to: { name: this.Names.R_CONTENT_STORAGE_CONTENTS },
              },
              {
                icon: 'fal fa-list',
                title: 'Список копирайтов',
                to: { name: this.Names.R_CONTENT_STORAGE_COPYRIGHT },
                exact: true,
              },
            ],
          },
        ],
      ];

      return items;
    },
  },
};
</script>

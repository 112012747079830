/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const ChoicesByQuestion = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/ByQuestion', method: methods.HTTP_POST, data, ...options });
export const ChoicesCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/Create', method: methods.HTTP_POST, data, ...options });
export const ChoicesDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const ChoicesGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/Get', method: methods.HTTP_POST, data, ...options });
export const ChoicesIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/Index', method: methods.HTTP_POST, data, ...options });
export const ChoicesUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.choices.v1.ChoicesAPI/Update', method: methods.HTTP_POST, data, ...options });
export const LevelsByTopic = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/ByTopic', method: methods.HTTP_POST, data, ...options });
export const LevelsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const LevelsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const LevelsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const LevelsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const LevelsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.levels.v1.LevelsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const QuestionsByLevel = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/ByLevel', method: methods.HTTP_POST, data, ...options });
export const QuestionsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const QuestionsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const QuestionsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const QuestionsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const QuestionsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.questions.v1.QuestionsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const TestsBySkill = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/BySkill', method: methods.HTTP_POST, data, ...options });
export const TestsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const TestsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const TestsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const TestsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const TestsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.tests.v1.TestsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const TopicsByTest = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.topics.v1.TopicsAPI/ByTest', method: methods.HTTP_POST, data, ...options });
export const TopicsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.topics.v1.TopicsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const TopicsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.topics.v1.TopicsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const TopicsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.topics.v1.TopicsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const TopicsById = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.lxp.topics.v1.TopicsAPI/Get', method: methods.HTTP_POST, data, ...options });

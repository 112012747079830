import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class LicenseService extends BaseService {
  async getByCompanyOrAccount(dto) {
    const { accountId, companyId } = dto;
    const response = await this.repositories.license.getByCompanyOrAccount(accountId, companyId);
    const licenses = response.licence.map((l) => this.factories.license.create(l));
    const errors = [];

    return [
      licenses,
      errors,
    ];
  }

  async createLicense(dto) {
    const { licenceList } = dto;
    const factoryList = licenceList.map((el) => this.factories.license.create(el));

    await Promise.all(factoryList.map(async (el) => {
      await this.repositories.license.createLicense(el);
    }));

    const errors = [];

    return [
      factoryList,
      errors,
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  async deactivate(dto) {
    const { id } = dto;

    const licence = await this.repositories.license.deactivate(id);
    const error = [];

    return [
      licence,
      error,
    ];
  }

  async deactivateList(dtoList) {
    await Promise.all(dtoList.map((el) => this.repositories.license.deactivate(el.id)));

    const errors = [];
    return [
      dtoList,
      errors,
    ];
  }
}

export default LicenseService;

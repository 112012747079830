/* eslint-disable import/prefer-default-export */

class BaseRepository {
  constructor() {
    this.collection = [];
  }

  async add(entity) {
    this.collection.push(entity);

    return entity;
  }

  async find(id) {
    const result = this.collection.find((entity) => entity.id === id);

    if (!result) {
      throw new Error('Entity not found');
    }

    return result;
  }

  // eslint-disable-next-line
  async update() { }
}

export { BaseRepository };

import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

// TODO: удалить id
class TeamHttpRepository extends BaseRepository {
  async add(team) {
    const data = {
      id: team.id,
      name: team.name,
      displayName: team.displayName,
      teamType: team.teamType,
      companyId: team.companyId,
      externalId: team.externalId,
    };

    const { id } = await API.Orgstructure.teamCreate(data);
    Object.assign(team, { id });

    this.collection.push(team);

    return team;
  }

  // eslint-disable-next-line
  async createLink(team, parentId) {
    const data = {
      parentId,
      childId: team.id,
      companyId: team.companyId,
    };

    await API.Orgstructure.teamCreateLink(data);
  }

  // eslint-disable-next-line
  async deleteLink(team, parentId) {
    const data = {
      parentId,
      childId: team.id,
      companyId: team.companyId,
    };

    await API.Orgstructure.teamDeleteLink(data);
  }

  // eslint-disable-next-line
  async update(team) {
    const data = {
      id: team.id,
      companyId: team.companyId,
      team: {
        name: team.name,
        displayName: team.displayName,
        externalId: team.externalId,
      },
    };

    await API.Orgstructure.teamEdit(data);
  }

  // eslint-disable-next-line
  async getTeam(id, companyId) {
    const { team } = await API.Orgstructure.teamGet({ id, companyId });
    return team;
  }

  // eslint-disable-next-line
  async getTeams(companyId) {
    const { teams } = await API.Orgstructure.teamIndex(companyId);
    return teams;
  }
}

export default TeamHttpRepository;

<template>
  <TTModuleMenu
    :items-tree="menuItems"
    hide-sign-out
  />
</template>

<script>
export default {
  name: 'AppOnboardingAsideLeft',

  inject: ['Names'],

  computed: {
    menuItems() {
      const items = [
        [
          {
            title: 'Главная',
            to: { name: this.Names.R_ONBOARDING_HOME },
            icon: 'fal fa-columns',
            exact: true,
          },
        ],
      ];

      return items;
    },
  },
};
</script>

import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class StepHttpRepository extends BaseRepository {
  async add(step) {
    const data = {
      step: {
        name: step.name,
        description: step.description,
        // order: null,
        stepType: step.type,
        default: step.defaultLevel,
        duration: step.duration,
        parameters: step.parameters ? JSON.parse(step.parameters) : '',
        instrumentId: step.instrumentId,
        procedureId: step.procedureId,
      },
    };
    const { id } = await API.Assessment.ProceduresStepsCreate({ data });
    Object.assign(step, { id });

    this.collection.push(step);
  }

  // eslint-disable-next-line
  async index(procedureId, page, limit) {
    const responce = await API.Assessment.ProceduresStepsIndex({ data: { procedureId, page, limit } });
    return responce;
  }

  // eslint-disable-next-line
  async get(stepId, procedureId) {
    const { step } = await API.Assessment.ProceduresStepsGet({ data: { stepId, procedureId } });
    return step;
  }

  // eslint-disable-next-line
  async update(step) {
    await API.Assessment.ProceduresStepsUpdateInfo({
      data: {
        procedureId: step.procedureId,
        stepId: step.id,
        name: step.name,
        description: step.description,
      },
    });
    if (!step.hasResult) {
      await API.Assessment.ProceduresStepsUpdate({
        data: {
          procedureId: step.procedureId,
          id: step.id,
          default: step.defaultLevel,
          duration: step.duration,
          parameters: step.parameters ? JSON.parse(step.parameters) : '',
          stepType: step.type,
          instrumentId: step.instrumentId,
        },
      });
    }
    this.collection.push(step);
  }

  // eslint-disable-next-line
  async find(id) {
    throw new Error('Not Implemented');
  }
}

export default StepHttpRepository;

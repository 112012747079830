<template>
  <TTModuleMenu
    :items-tree="menuItems"
    hide-sign-out
  />
</template>

<script>
export default {
  name: 'AppCoreAsideLeft',

  inject: ['Names'],

  computed: {
    menuItems() {
      const items = [
        [
          {
            title: 'Главная',
            to: { name: this.Names.R_CORE_HOME },
            exact: true,
            icon: 'fal fa-columns',
          },
          {
            icon: 'fal fa-building fa-fwG',
            title: 'Аккаунты',
            children: [
              {
                icon: 'fal fa-list',
                title: 'Аккаунты',
                to: { name: this.Names.R_ACCOUNTS },
              },
              {
                icon: 'fal fa-list',
                title: 'Компании',
                to: { name: this.Names.R_ACCOUNT_SYS_COMPANIES },
              },
              {
                icon: 'fal fa-paper-plane fa-fw',
                title: 'Приглашения',
                to: { name: this.Names.R_INVITATIONS },
              },
            ],
          },
          {
            icon: 'fal fa-sitemap fa-fw',
            title: 'Оргструктура',
            children: [
              {
                icon: 'fal fa-list',
                title: 'Список компаний',
                to: { name: this.Names.R_ORGSTRUCTURE },
              },
              {
                icon: 'fal fa-search',
                title: 'Поиск штатных должностей',
                to: { name: this.Names.R_ORGSTRUCTURE_POSITION_SEARCH },
              },
              {
                icon: 'fal fa-plus-circle',
                title: 'Создать штатную должность',
                to: { name: this.Names.R_ORGSTRUCTURE_POSITION_CREATE },
              },
              {
                icon: 'fal fa-link',
                title: 'Связать штатные должности',
                to: { name: this.Names.R_ORGSTRUCTURE_POSITION_TO_POSITION },
              },
            ],
          },
          {
            icon: 'fal fa-user-friends fa-fw',
            title: 'Пользователи',
            children: [
              {
                icon: 'fal fa-search',
                title: 'Поиск пользователя',
                to: { name: this.Names.R_AUTHENTICATION_USERS_SEARCH },
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать пользователя',
                to: { name: this.Names.R_AUTHENTICATION_USERS_CREATE },
              },
            ],
          },
          {
            icon: 'fal fa-address-card fa-fw',
            title: 'Профилирование',
            children: [
              {
                icon: 'fal fa-search',
                title: 'Матрица компании',
                to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX },
                exact: true,
              },
              {
                icon: 'fal fa-search',
                title: 'Матрица пользователя',
                to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX },
                exact: true,
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать позицию',
                to: { name: this.Names.R_COMPETENCY_MATRIX_POSITION_CREATE },
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать компетенцию',
                to: { name: this.Names.R_COMPETENCY_MATRIX_COMPETENCY_CREATE },
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать навык',
                to: { name: this.Names.R_COMPETENCY_MATRIX_SKILL_CREATE },
              },
              {
                icon: 'fal fa-list',
                title: 'Список позиций',
                to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS },
                exact: true,
              },
              {
                icon: 'fal fa-list',
                title: 'Список компетенций',
                to: { name: this.Names.R_COMPETENCY_MATRIX_COMPETENCIES },
                exact: true,
              },
              {
                icon: 'fal fa-list',
                title: 'Список навыков',
                to: { name: this.Names.R_COMPETENCY_MATRIX_SKILLS },
                exact: true,
              },
            ],
          },
          {
            icon: 'fal fa-tools fa-fw',
            title: 'Инструменты',
            children: [
              {
                icon: 'fal fa-images fa-fw',
                title: 'Загрузка картинок',
                to: { name: this.Names.R_GOGHA_UPLOAD },
              },
              {
                icon: 'fal fa-file-alt fa-fw',
                title: 'Мета',
                to: { name: this.Names.R_META_SEARCH },
              },
              {
                icon: 'fal fa-file-certificate fa-fw',
                title: 'Приложения (лицензии)',
                to: { name: this.Names.R_LICENSE_APPS },
              },
            ],
          },
          {
            icon: 'fal fa-cog fa-fw',
            title: 'Настройки',
            children: [
              {
                icon: 'fal fa-list',
                title: 'Маршруты',
                to: { name: this.Names.R_SYSADMIN_ROUTES },
                exact: true,
              },
              {
                icon: 'fal fa-list',
                title: 'Шаблоны писем',
                to: { name: this.Names.R_NOTIFICATION_TEMPLATES },
                exact: true,
              },
            ],
          },
        ],
      ];

      return items;
    },
  },
};
</script>

import {
  APP_ROUTES_NAMES,
  CORE_ROUTES_NAMES,
  ENGAGEMENT_ROUTES_NAMES,
  LEARNING_ROUTES_NAMES,
  GOALS_ROUTES_NAMES,
  ONBOARDING_ROUTES_NAMES,
} from '@/plugins/vue-router/routes/names';

export default {
  name: 'RouteNamesProvider',

  provide: {
    // Note: Вынуждены делать операцию spread для удаления Symbol, чтобы devtools не думал, что это ES модуль
    Names: {
      ...APP_ROUTES_NAMES,
      ...CORE_ROUTES_NAMES,
      ...ENGAGEMENT_ROUTES_NAMES,
      ...LEARNING_ROUTES_NAMES,
      ...GOALS_ROUTES_NAMES,
      ...ONBOARDING_ROUTES_NAMES,
    },
  },

  render() {
    return this.$slots.default;
  },
};

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const ActivateUser = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/ActivateUser', method: methods.HTTP_POST, data });
export const DeactivateUser = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/DeactivateUser', method: methods.HTTP_POST, data });
export const DeleteAccount = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/DeleteAccount', method: methods.HTTP_POST, data });
export const DropPassword = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/DropPassword', method: methods.HTTP_POST, data });
export const getUser = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/GetUser', method: methods.HTTP_POST, data });
export const getUsers = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/GetUsersBatch', method: methods.HTTP_POST, data });
export const getUserIDByLogin = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/GetUserIDByLogin', method: methods.HTTP_POST, data });
export const NewEmailWithoutVerification = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/NewEmailWithoutVerification', method: methods.HTTP_POST, data });
export const NewPhoneWithoutVerification = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/NewPhoneWithoutVerification', method: methods.HTTP_POST, data });
export const NewUser = (data = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/NewUser', method: methods.HTTP_POST, data });
export const userUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/UpdateUser', method: methods.HTTP_POST, data, ...options });
export const addSapId = (data = {}, ...options) => transport({ url: '/twirp/tt.core.authentication.v1.AuthenticationAPI/NewSAP', method: methods.HTTP_POST, data, ...options });

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const createCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Create', method: methods.HTTP_POST, data });
export const blockCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Block', method: methods.HTTP_POST, data });
export const activateCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Activate', method: methods.HTTP_POST, data });
export const createTopic = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/Create', method: methods.HTTP_POST, data });
export const getTopic = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/Get', method: methods.HTTP_POST, data });
export const updateTopic = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/Update', method: methods.HTTP_POST, data });
export const archiveTopic = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/Archive', method: methods.HTTP_POST, data });
export const getTopics = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/Index', method: methods.HTTP_POST, data });
export const searchContentItems = (data = {}) => transport({ url: '/twirp/tt.contentplayer.contentitems.v1.ContentItemsAPI/SearchByText', method: methods.HTTP_POST, data });
export const copyrightCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const copyrightArchive = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/Archive', method: methods.HTTP_POST, data, ...options });
export const copyrightIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const getCopyright = (data = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/Get', method: methods.HTTP_POST, data });
export const getCoursesItems = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Index', method: methods.HTTP_POST, data });
export const archiveCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Archive', method: methods.HTTP_POST, data });
export const searchTopics = (data = {}) => transport({ url: '/twirp/tt.contentplayer.topics.v1.TopicsAPI/SearchByText', method: methods.HTTP_POST, data });
export const getCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Get', method: methods.HTTP_POST, data });
export const updateCourse = (data = {}) => transport({ url: '/twirp/tt.contentplayer.courses.v1.CoursesAPI/Update', method: methods.HTTP_POST, data });
export const updateCopyright = (data = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/Update', method: methods.HTTP_POST, data });
export const archiveContent = (data = {}) => transport({ url: '/twirp/tt.contentplayer.contentitems.v1.ContentItemsAPI/Archive', method: methods.HTTP_POST, data });
export const copyrightContentItems = (data = {}) => transport({ url: '/twirp/tt.contentplayer.copyrights.v1.CopyrightsAPI/CopyrightContentItems', method: methods.HTTP_POST, data });

// https://github.com/severgroup-tt/accounts/blob/master/rpc/tt/core/accounts/accounts/v1/common.proto#L8

const DEFAULT_LICENSE_APP = {
  id: null,
  name: null,
  active: null,
  bundleName: null,
};

class LicenseAppEntity {
  constructor(dto = DEFAULT_LICENSE_APP) {
    this.id = dto.id;
    this.name = dto.name;
    this.active = dto.active;
    this.bundleName = dto.bundleName;
  }
}

export default LicenseAppEntity;

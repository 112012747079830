<template>
  <VApp id="HolyGrailLayout">
    <RouterView name="nav" />

    <RouterView name="aside-left" />

    <VMain :class="mainClasses">
      <RouterView />

      <RouterView name="content-footer" />
    </VMain>

    <RouterView name="aside-right" />

    <RouterView name="footer" />

    <!-- TODO: плохая идея, но это должно быть тут внутри v-app -->
    <VOverlay
      v-if="isLoading"
      :value="isLoading"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
    >
      <VProgressCircular
        indeterminate
        color="accent"
      />
    </VOverlay>

    <VSnackbar
      v-if="snackbar.value"
      :value="snackbar.value"
      :timeout="snackbar.timeout"
      top
      :color="snackbar.color"
      @input="toggleSnack"
    >
      {{ snackbar.message }}

      <template #action="{ attrs }">
        <VBtn
          color="white"
          text
          v-bind="attrs"
          @click="toggleSnack"
        >
          закрыть
        </VBtn>
      </template>
    </VSnackbar>
  </VApp>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'HolyGrailLayout',

  computed: {
    // TODO: плохая идея, но это должно быть тут для overlay и snackbar -->
    ...mapState(['snackbar', 'isLoading']),

    mainClasses() {
      if (this.$vuetify.application.left) {
        return {
          'tt-main__xl_menu': this.$vuetify.breakpoint.xl,
          'tt-main__lg_menu': this.$vuetify.breakpoint.lg,
          'tt-main__md_menu': this.$vuetify.breakpoint.md,
          'tt-main__sm_menu': this.$vuetify.breakpoint.sm,
        };
      }
      return {};
    },
  },

  methods: {
    ...mapMutations(['toggleSnack']),
  },
};
</script>

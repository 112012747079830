/* eslint-disable import/prefer-default-export */

class BaseService {
  constructor({ repositories, entities, factories }) {
    this.repositories = repositories;
    this.entities = entities;
    this.factories = factories;
  }
}

export { BaseService };

import { AppEngagementAsideLeft as AppAsideLeft, AppNav, HolyGrailLayout } from '@front.backoffice/shared';
import * as Names from './app.routes.names';

const MODULE_PREFIX = '/app/engagement';

export default [
  {
    path: MODULE_PREFIX,
    component: HolyGrailLayout,
    children: [
      {
        path: '',
        name: Names.R_ENGAGEMENT_HOME,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/Home.vue'),
        },
      },

      {
        path: 'surveys',
        name: Names.R_ENGAGEMENT_SURVEYS,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/Surveys.vue'),
        },
      },

      {
        path: 'surveys/create',
        name: Names.R_ENGAGEMENT_SURVEYS_CREATE,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/SurveysCreate.vue'),
        },
      },

      {
        path: 'surveys/:surveyId',
        name: Names.R_ENGAGEMENT_SURVEYS_VIEW,
        components: {
          nav: AppNav,
          'aside-left': AppAsideLeft,
          default: () => import('./views/SurveysView.vue'),
        },
      },
    ],
  },
];

import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class GroupHttpRepository extends BaseRepository {
  async add(group) {
    const data = {
      group: {
        companyId: group.companyId,
        teamId: group.teamId,
        userId: group.userId,
      },
    };
    const { id } = await API.Assessment.GroupsCreate({ data });
    Object.assign(group, { id });

    this.collection.push(group);
  }

  // eslint-disable-next-line
  async addProcedure(group, procedure) {
    const data = {
      groupId: group.id,
      procedureId: procedure.id,
    };
    await API.Assessment.GroupsAddProcedure({ data });
  }

  // eslint-disable-next-line
  async find(id) {
    throw new Error('Not Implemented');
  }

  // eslint-disable-next-line
  async getAllByCompany(companyId, page, limit) {
    const data = {
      companyId,
      page,
      limit,
    };
    const response = await API.Assessment.GroupsIndexByCompany({ data });

    return response;
  }

  // eslint-disable-next-line
  async get(id) {
    const data = { id };
    const response = await API.Assessment.GroupsGet({ data });

    return response;
  }

  // eslint-disable-next-line
  async getProcedures(groupId, page, limit) {
    const data = {
      groupId,
      page,
      limit,
    };
    const response = await API.Assessment.GroupsProceduresIndex({ data });

    return response;
  }
}

export default GroupHttpRepository;

import { DefaultLayout } from '@front.backoffice/shared';
import { CORE_ROUTES } from '@front.backoffice/core';
import { ENGAGEMENT_ROUTES } from '@front.backoffice/engagement';
import { LEARNING_ROUTES } from '@front.backoffice/learning';
import { GOALS_ROUTES } from '@front.backoffice/goals';
import { ONBOARDING_ROUTES } from '@front.backoffice/onboarding';
import * as Names from './names';
import routesAuth from './routesAuth';
import routesErrors from './routesErrors';

export default [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        redirect: { name: Names.CORE_ROUTES_NAMES.R_CORE_HOME },
      },
    ],
  },

  ...CORE_ROUTES,
  ...ENGAGEMENT_ROUTES,
  ...LEARNING_ROUTES,
  ...GOALS_ROUTES,
  ...ONBOARDING_ROUTES,

  ...routesAuth,
  ...routesErrors,

  {
    path: '*',
    props: true,
    redirect: (to) => {
      const moduleName = to.path.split('/')[2] || 'core';
      return { name: Names.R_ERROR_404, params: { moduleName } };
    },
  },
];

import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class CompanyService extends BaseService {
  async activate(dto) {
    const id = await this.repositories.company.activate(dto.id);
    const errors = [];
    return [id, errors];
  }

  async deactivate(dto) {
    const id = await this.repositories.company.deactivate(dto.id);
    const errors = [];
    return [id, errors];
  }

  async getUsers(dto) {
    const admins = await this.repositories.company.getUsers(dto.id);
    const errors = [];
    return [admins, errors];
  }
}

export default CompanyService;

import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class TeamFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const entity = new this.entities.Team({
      id,
      name: dto.name,
      displayName: dto.displayName,
      teamType: dto.teamType,
      companyId: dto.companyId,
      externalId: dto.externalId,
      parent: dto.parent,
      parentId: dto.parentId,
      path: dto.path,
    });

    return entity;
  }
}

export default TeamFactory;

import Vue from 'vue';
import {
  i18n,
  router,
  store,
  vuetify,
} from '@/plugins';
import App from '@/App.vue';

Vue.config.productionTip = false;

// TODO: конфиги в <PACKAGE_ROOT>
new Vue({
  name: 'TalentTech',

  i18n,
  router,
  store,
  vuetify,

  created() {
    /* global google */
    const IdConfiguration = {
      client_id: process.env.VUE_APP_GSI_CLIENT_ID,
      /* @ts-ignore */
      callback: async (r) => {
        /* @ts-ignore */
        this.$di.api.GSIToken.set(r.credential);
        const data = { idToken: r.credential };
        try {
          /* @ts-ignore */
          const res = await this.$di.api.Sysadmin.oauthSignIn({ data });
          /* @ts-ignore */
          this.$di.api.AccessToken.set(res.jwt);
          /* @ts-ignore */
          this.$router.push(this.$route.query.redirect || '/app/home');
        } catch (err) {
          /* @ts-ignore */
          this.$di.notify.errorHandler(new Error('Произошла ошибка обратитесь к администратору'));
        } finally {
          // no-finally
        }
      },
      auto_select: true,
      cancel_on_tap_outside: false,
      /* @ts-ignore */
      state_cookie_domain: new URL(window.location).hostname.replace(/.*(talenttechlab\.(com|org))$/, '.$1'),
      ux_mode: 'popup',
    };
    /* @ts-ignore */
    google.accounts.id.initialize(IdConfiguration);

    /* @ts-ignore */
    this.$di.api.KeycloakAuth.create();
  },

  render: (h) => h(App),
}).$mount('#app');

import {
  snakeCase, mapKeys, mapValues, isObject, isArray,
} from 'lodash';

function toSnakeCase(obj) {
  if (isArray(obj)) {
    return obj.map((i) => toSnakeCase(i));
  }

  if (isObject(obj)) {
    return mapValues(
      mapKeys(obj, (v, k) => (!k.startsWith('_') && !k.includes('-') ? snakeCase(k) : k)),
      (value) => toSnakeCase(value),
    );
  }

  return obj;
}

export default toSnakeCase;

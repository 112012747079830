<template>
  <AppNavbar
    :items="items"
  >
    <template #brand>
      <TTBtn
        text
        color="tt-light-mono-100"
        :to="{ name : Names.R_CORE_HOME }"
        class="pa-0"
      >
        <div class="d-flex align-center">
          <VImg
            width="33"
            height="9"
            :src="require('../assets/cogs.svg')"
          />
          <div class="ml-3 tt-text-caption">
            Backoffice
          </div>
        </div>
      </TTBtn>
    </template>

    <template #company>
      <AppProfileMenu
        :user-name="userName"
        :user-avatar="userPicture"
        :user-initials="avatarText"
        :actions="actions"
        @sign-out="handleSignOut"
      />
    </template>
  </AppNavbar>
</template>

<script>
import jwt from 'jsonwebtoken';
import AppNavbar from '../../../app/vendors/titify/src/ui/widgets/AppNavbar.vue';
import AppProfileMenu from '../../../app/vendors/titify/src/ui/widgets/AppProfileMenu.vue';

export default {
  name: 'AppNav',
  components: {
    AppNavbar,
    AppProfileMenu,
  },

  inject: ['Names'],

  props: {
    clippedRight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userName: null,
      givenName: null,
      familyName: null,
      userPicture: null,
      actions: [
        {
          id: 'sign-out',
          icon: 'fal fa-sign-out',
          text: 'Выйти',
          color: 'red',
        },
      ],
    };
  },

  computed: {
    items() {
      return [
        { text: 'Платформа', to: { name: this.Names.R_CORE_HOME } },
        { text: 'Обучение', to: { name: this.Names.R_LEARNING_HOME } },
        { text: 'Цели', to: { name: this.Names.R_GOALS_HOME } },
        { text: 'Опросы', to: { name: this.Names.R_ENGAGEMENT_HOME } },
        { text: 'Адаптация', to: { name: this.Names.R_ONBOARDING_HOME } },
      ];
    },
    avatarText() {
      const {
        givenName, familyName,
      } = this;

      if (givenName && familyName) {
        return `${givenName[0]}${familyName[0]}`;
      }

      return null;
    },
  },
  created() {
    const token = this.$di.api.GSIToken.get() || this.$di.api.KeycloakToken.get();
    const decode = jwt.decode(token);

    this.userName = decode.name;
    this.givenName = decode.given_name;
    this.familyName = decode.family_name;
    this.userPicture = decode.picture;
  },

  methods: {
    handleSignOut() {
      this.$di.api.AccessToken.remove();
      this.$di.api.GSIToken.remove();
      this.$di.api.KeycloakToken.remove();

      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.company-subtitle {
  font-size: 0.625rem;
}

.custom-item.v-btn--active {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: map-get($tt-light-mono-100, 'base');
  }
}

.v-btn {

  &::before {
    opacity: 0 !important;
  }

  &:focus {
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>

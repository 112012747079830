/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const GroupsAddProcedure = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/AddProcedure', method: methods.HTTP_POST, data, ...options });
export const GroupsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const GroupsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const GroupsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const GroupsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const GroupsIndexByCompany = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/IndexByCompany', method: methods.HTTP_POST, data, ...options });
export const GroupsProceduresIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/ProceduresIndex', method: methods.HTTP_POST, data, ...options });
export const GroupsRemoveProcedure = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/RemoveProcedure', method: methods.HTTP_POST, data, ...options });
export const InstrumentsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.instruments.v1.InstrumentsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const InstrumentsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.instruments.v1.InstrumentsAPI/Delete', method: methods.HTTP_POST, data, ...options });
export const InstrumentsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.instruments.v1.InstrumentsAPI/Get', method: methods.HTTP_POST, data, ...options });
export const InstrumentsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.instruments.v1.InstrumentsAPI/Index', method: methods.HTTP_POST, data, ...options });
export const InstrumentsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.instruments.v1.InstrumentsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const ProceduresCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/Create', method: methods.HTTP_POST, data, ...options });
export const ProceduresGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/Get', method: methods.HTTP_POST, data, ...options });
export const ProceduresIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.groups.v1.GroupsAPI/ProceduresIndex', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsCreate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsCreate', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsDelete = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsDelete', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsIndex = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsIndex', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsGet = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsGet', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsUpdate', method: methods.HTTP_POST, data, ...options });
export const ProceduresStepsUpdateInfo = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/StepsInfoUpdate', method: methods.HTTP_POST, data, ...options });
export const ProceduresUpdate = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/Update', method: methods.HTTP_POST, data, ...options });
export const ProceduresArchive = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.procedures.v1.ProceduresAPI/ToArchive', method: methods.HTTP_POST, data, ...options });
export const DownloadFile = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.historical.v1.HistoricalAPI/DownloadFile', method: methods.HTTP_POST, data, ...options });
export const PrepareUrl = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.learning.historical.v1.HistoricalAPI/PrepareUrl', method: methods.HTTP_POST, data, ...options });
export const IndexBySkill = (data = {}, options = {}) => transport({ url: '/twirp/tt.learning.teststopics.v1.TeststopicsAPI/IndexBySkill', method: methods.HTTP_POST, data, ...options });

const DEFAULT_SP = {
  id: null,
  name: null,
  teamId: null,
  role: null,
  externalId: null,
  competencyGroupIds: null,
  staffPositionToStaffPositions: null,
};

class StaffPositionEntity {
  constructor(dto = DEFAULT_SP) {
    this.id = dto.id;
    this.name = dto.name;
    this.teamId = dto.teamId;
    this.role = dto.role;
    this.externalId = dto.externalId;
    this.competencyGroupIds = dto.competencyGroupIds;
    this.staffPositionToStaffPositions = dto.staffPositionToStaffPositions;
  }
}

export default StaffPositionEntity;

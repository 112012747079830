// Note: у процедуры нет "цикличности" wtf?
// eslint-disable-next-line max-len, vue/max-len
// @see: https://github.com/severgroup-tt/assessment/blob/0d6dff38f4d7def32bba9dd042c4ac27b0d7f7e6/rpc/tt/learning/procedures/v1/common.proto#L11
const DEFAULT_PROCEDURE = {
  id: null,
  name: null,
  startAt: null,
  finishedAt: null,
  continuous: null,
  cooldown: null,
  competencyId: null,
  skillId: null,
  steps: null,
  groupId: null,
  description: null,
  state: null,
};

class ProcedureEntity {
  constructor(procedure = DEFAULT_PROCEDURE, groupId) {
    this.id = procedure.id;
    this.name = procedure.name;
    this.startAt = procedure.startAt;
    this.finishedAt = procedure.finishedAt;
    this.continuous = procedure.continuous;
    this.cooldown = procedure.cooldown;
    this.competencyId = procedure.competencyId;
    this.skillId = procedure.skillId;
    this.steps = procedure.steps;
    this.description = procedure.description;
    this.groupId = groupId;
    this.state = procedure.state;
  }

  addSteps(steps) {
    steps.forEach((step) => this.addStep(step));
  }

  addStep(step) {
    this.steps.push(step);
  }
}

export default ProcedureEntity;

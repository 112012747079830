import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class LicenseAppFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const entity = new this.entities.LicenseApp({
      id,
      name: dto.name,
      active: dto.active,
      bundleName: dto.bundleName,
    });

    return entity;
  }
}

export default LicenseAppFactory;

import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class AccountService extends BaseService {
  async createCompany(dto) {
    let company;
    const account = this.factories.account.create(dto);
    const errors = [];

    if (account.id.startsWith('GENERATED_')) {
      await this.repositories.account.create(account);
      company = await this.factories.company.create({ ...dto, accountId: account.id });
      await this.repositories.company.create(company);
      await this.repositories.company.uploadImage(company, dto.imageFile);
    }

    return [company, errors];
  }

  async getCompany(dto) {
    const resp = await this.repositories.company.get(dto.id);
    const company = this.factories.company.create(resp);
    const errors = [];
    return [company, errors];
  }

  async getAccount(dto) {
    const resp = await this.repositories.account.get(dto.id);
    const account = this.factories.account.create(resp);
    const errors = [];
    return [account, errors];
  }
}

export default AccountService;

// https://github.com/severgroup-tt/accounts/blob/master/rpc/tt/core/accounts/accounts/v1/common.proto#L8

const DEFAULT_ACCOUNT = {
  id: null,
  name: null,
  active: true,
};

class AccountEntity {
  constructor(dto = DEFAULT_ACCOUNT) {
    this.id = dto.id;
    this.name = dto.name;
    this.active = dto.active;
  }
}

export default AccountEntity;

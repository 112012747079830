import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class StepFactory extends BaseFactory {
  create(dto, procedureId) {
    const parameters = dto.id && (typeof dto.parameters === 'object') ? JSON.stringify(dto.parameters) : dto.parameters;
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const step = new this.entities.Step({
      id,
      name: dto.name,
      description: dto.description,
      order: dto.order,
      type: String(dto.stepType || dto.type).toUpperCase(),
      defaultLevel: String(dto.default || dto.defaultLevel).toUpperCase(),
      duration: dto.duration,
      parameters,
      instrumentId: dto.instrumentId,
      hasResult: dto.hasResult,
    }, procedureId);

    return step;
  }
}

export default StepFactory;

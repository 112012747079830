import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class LicenseHttpRepository extends BaseRepository {
  // eslint-disable-next-line
  async getByCompanyOrAccount(accountId, companyId) {
    const data = {
      accountId,
      companyId,
    };

    return API.License.licenseByCompanyOrAccount(data);
  }
  // eslint-disable-next-line
  async createLicense(licence) {
    const licenceWithDeletedId = { ...licence };
    delete licenceWithDeletedId.id;

    const data = {
      licence: licenceWithDeletedId,
    };

    const { id } = await API.License.createLicense(data);
    Object.assign(licence, { id });

    return licence;
  }

  // eslint-disable-next-line class-methods-use-this
  deactivate(id) {
    const data = {
      id,
    };

    return API.License.deactivate(data);
  }
}

export default LicenseHttpRepository;

/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const StaffPositionCreateV2 = (data = {}, options) => transport({ url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/Create', method: methods.HTTP_POST, data, ...options });
export const staffPositionsByIds = (data = {}, options) => transport({ url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/GetStaffPositionsByIds', method: methods.HTTP_POST, data, ...options });
export const unlinkStaffPositions = (data = {}, options) => transport({ url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/UnlinkStaffPositions', method: methods.HTTP_POST, data, ...options });
export const peopleConnected = ({ personId, toPersonId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/ConnectedPeople', data: { personId, toPersonId, companyId }, ...options });
export const peopleCreateLinkToPerson = ({ fromPersonId, toPerson, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/CreateLinkToPerson', data: { fromPersonId, toPerson, companyId }, ...options });
export const peopleCreateLinkToTeam = ({ person, teamId, head }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/CreateLinkToTeam', data: { person, teamId, head }, ...options });
export const peopleDeleteLinkToPerson = ({ fromPersonId, toPersonId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/DeleteLinkToPerson', data: { fromPersonId, toPersonId, companyId }, ...options });
export const peopleDeleteLinkToTeam = ({ personId, teamId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/DeleteLinkToTeam', data: { personId, teamId }, ...options });
export const peopleGetPersonCompanies = (personId, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/GetPersonCompanies', data: { personId }, ...options });
export const peopleGetTeamPeople = (tid, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/GetTeamPeople', data: { teamId: tid }, ...options });
export const peopleGetTeams = ({ personId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/GetTeams', data: { personId, companyId }, ...options });
export const peopleIsPersonHasSubordinates = ({ personId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/IsPersonHasSubordinates', data: { personId, companyId }, ...options });
export const peopleIsPersonInTeam = ({ personId, companyId, teamId, checkChildrenTeams }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/IsPersonHasSubordinates', data: { personId, companyId, teamId, checkChildrenTeams }, ...options });
export const peoplePersonToPersonRelations = ({ personId, companyId, toPersonId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.people.v1.PeopleAPI/IsPersonHasSubordinates', data: { personId, companyId, toPersonId }, ...options });
export const positionCreate = ({ staffPosition }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v1.StaffpositionsAPI/Create', data: { staffPosition }, ...options });
export const positionDelete = ({ id }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v1.StaffpositionsAPI/Delete', data: { id }, ...options });
export const positionSearch = (filters, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v1.StaffpositionsAPI/Search', data: { ...filters }, ...options });
export const positionShow = ({ id }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v1.StaffpositionsAPI/Get', data: { id }, ...options });
export const positionUpdate = ({ id, staffPosition }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v1.StaffpositionsAPI/Update', data: { id, staffPosition }, ...options });
export const positionsUserCount = (data = {}) => transport({ url: '/twirp/tt.core.orgstructure.people.v2.PeopleAPI/UsersCountByCompetencyGroup', method: methods.HTTP_POST, data });
export const teamChildren = ({ id, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Children', data: { id, companyId }, ...options });
export const teamCreate = ({ id, name, displayName, teamType, companyId, externalId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Create', data: { team: { id, name, displayName, teamType, companyId, externalId } }, ...options });
export const teamCreateLink = ({ parentId, childId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/CreateLink', data: { parentId, childId, companyId }, ...options });
export const teamDelete = (data, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Delete', data: { ...data }, ...options });
export const teamDeleteLink = (data, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/DeleteLink', data, ...options });
export const teamEdit = (data = {}, ...options) => transport({ url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const teamFetch = ({ externalId, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Fetch', data: { externalId, companyId }, ...options });
export const teamGet = ({ id, companyId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Get', data: { id, companyId }, ...options });
export const getTeamStaffPositions = ({ teamId }, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/GetStaffPositionsByTeam', data: { teamId }, ...options });
export const teamIndex = (cid, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Index', data: { companyId: cid }, ...options });
export const teamRoot = (cid, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/Root', data: { companyId: cid }, ...options });
export const teamTraverseCompany = (cid, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/TraverseCompany', data: { companyId: cid }, ...options });
export const teamTraverseTeam = (id, options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.teams.v1.TeamsAPI/TraverseTeam', data: { id }, ...options });
export const teamStaffPosition = ({ teamId }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/TeamStaffPositions', data: { teamId }, ...options });
export const linkStaffPositions = ({ fromStaffPositionId, toStaffPositionId, role }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/LinkStaffPositions', data: { fromStaffPositionId, toStaffPositionId, role }, ...options });
export const getStaffPositionsByUser = ({ userId }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/GetStaffPositionsByUser', data: { userId }, ...options });
export const connectedStaffPositions = ({ staffPositionIds }, ...options) => transport({ method: methods.HTTP_POST, url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/ConnectedStaffPositions', data: { staffPositionIds }, ...options });
export const searchStaffPositionsInCompany = ({ companyId, query }, options) => transport({ url: '/twirp/tt.m9t.orgstructure.SearchAPI/SearchStaffPositionsInCompany', method: methods.HTTP_POST, data: { companyId, query }, ...options });
export const positionUpdateV2 = (data, options) => transport({ url: '/twirp/tt.core.orgstructure.staffpositions.v2.StaffpositionsAPI/Update', method: methods.HTTP_POST, data, ...options });
export const importsIndex = (data, options) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileImportAPI/ImportsIndex', method: methods.HTTP_POST, data, ...options });
export const importCreate = (data, options) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileImportAPI/CreateImport', method: methods.HTTP_POST, data, ...options });

// https://github.com/severgroup-tt/accounts/blob/master/rpc/tt/core/accounts/accounts/v1/common.proto#L8

const DEFAULT_LICENSE = {
  id: null,
  appName: null,
  licensee: {
    accountId: null,
    companyId: null,
  },
  duration: {
    startsAt: null,
    days: null,
  },
  totalUsers: null,
  involvedUsers: null,
  active: null,
  bundleName: null,
  type: null,
};

class LicenseEntity {
  constructor(dto = DEFAULT_LICENSE) {
    this.id = dto.id;
    this.appName = dto.appName;
    this.licensee = { ...dto.licensee };
    this.duration = { ...dto.duration };
    this.totalUsers = dto.totalUsers;
    this.involvedUsers = dto.involvedUsers;
    this.active = dto.active;
    this.bundleName = dto.bundleName;
    this.type = dto.type;
  }
}

export default LicenseEntity;

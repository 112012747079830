import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from './router';
import routes from './routes';

Vue.use(VueRouter);

const { BASE_URL: baseUrl } = process.env;

const router = createRouter({
  baseUrl,
  routes,
});

export * from '@/plugins/vue-router/routes/names';
export default router;

import { render, staticRenderFns } from "./TTAvatar.vue?vue&type=template&id=ae3d72f6&scoped=true&"
import script from "./TTAvatar.vue?vue&type=script&lang=ts&"
export * from "./TTAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./TTAvatar.scss?vue&type=style&index=0&id=ae3d72f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3d72f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})

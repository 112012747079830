/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const choiceCreate = ({ testId, questionId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}/choice`, method: methods.HTTP_POST, data });
export const choiceDelete = ({ testId, questionId, answerId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}/choice/${answerId}`, method: methods.HTTP_DELETE, data });
export const choiceUpdate = ({ testId, questionId, answerId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}/choice/${answerId}`, method: methods.HTTP_PUT, data });
export const choicesGet = ({ testId, questionId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}/choice`, method: methods.HTTP_GET, data });
export const indexCompany = (data = {}) => transport({ url: '/admin/company', method: methods.HTTP_GET, data });
export const indexSkill = (data = {}) => transport({ url: '/admin/skill', method: methods.HTTP_GET, params: data });
export const questionCreate = ({ testId, topicId, ...data }) => transport({ url: `/admin/test/${testId}/topic/${topicId}/question`, method: methods.HTTP_POST, data });
export const questionDelete = ({ testId, questionId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}`, method: methods.HTTP_DELETE, data });
export const questionGet = ({ testId, questionId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}`, method: methods.HTTP_GET, data });
export const questionUpdate = ({ testId, questionId, ...data }) => transport({ url: `/admin/test/${testId}/question/${questionId}`, method: methods.HTTP_PUT, data });
export const questionsGet = ({ testId, topicId, ...data }) => transport({ url: `/admin/test/${testId}/topic/${topicId}/question`, method: methods.HTTP_GET, data });
export const testCreate = (data = {}) => transport({ url: '/admin/test', method: methods.HTTP_POST, data });
export const testGet = ({ testId, ...data }) => transport({ url: `/admin/test/${testId}`, method: methods.HTTP_GET, data });
export const testUpdate = ({ testId, ...data }) => transport({ url: `/admin/test/${testId}`, method: methods.HTTP_PUT, data });
export const testRemove = ({ id, ...data }) => transport({ url: `/admin/test/${id}`, method: methods.HTTP_DELETE, data });
export const testsGet = ({ params, ...data }) => transport({ url: '/admin/test', method: methods.HTTP_GET, data, params });
export const topicCreate = ({ testId, ...data }) => transport({ url: `/admin/test/${testId}/topic`, method: methods.HTTP_POST, data });
export const topicDelete = ({ testId, topicId, ...data }) => transport({ url: `/admin/test/${testId}/topic/${topicId}`, method: methods.HTTP_DELETE, data });
export const topicUpdate = ({ testId, topicId, ...data }) => transport({ url: `/admin/test/${testId}/topic/${topicId}`, method: methods.HTTP_PUT, data });
export const topicsGet = ({ testId, ...data }) => transport({ url: `/admin/test/${testId}/topic`, method: methods.HTTP_GET, data });
export const uploadFile = ({ testId, data }) => transport({ url: `/admin/test/${testId}/question/import`, method: methods.HTTP_POST, data });

export const examResult = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/ExamResult', method: methods.HTTP_POST, data, ...options });
export const skillResult = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/SkillResult', method: methods.HTTP_POST, data, ...options });
export const skillTest = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/SkillTest', method: methods.HTTP_POST, data, ...options });
export const smsConfigGet = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/GetSmsConfig', method: methods.HTTP_POST, data, ...options });
export const smsConfigIndex = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/IndexSmsConfig', method: methods.HTTP_POST, data, ...options });
export const smsConfigToggle = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/ToggleSmsConfig', method: methods.HTTP_POST, data, ...options });
export const smsConfigUpdate = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/UpdateSmsConfig', method: methods.HTTP_POST, data, ...options });
export const tosConfigGet = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/GetTosConfig', method: methods.HTTP_POST, data, ...options });
export const tosConfigIndex = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/IndexTosConfig', method: methods.HTTP_POST, data, ...options });
export const tosConfigToggle = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/ToggleTosConfig', method: methods.HTTP_POST, data, ...options });
export const tosConfigUpdate = (data = {}, options = {}) => transport({ url: '/twirp/tt.talent.v1.TalentAPI/UpdateTosConfig', method: methods.HTTP_POST, data, ...options });

import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/plugins/vuex/modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: 0,
    snackbar: {
      value: false,
      message: '',
      color: '',
      timeout: 3000,
    },
  },
  mutations: {
    toggleLoading(state, number) {
      if (number) {
        state.isLoading = number;
        return false;
      }
      state.isLoading -= 1;
      return false;
    },
    toggleSnack(state, payload) {
      const { snackbar } = state;
      snackbar.value = !snackbar.value;
      snackbar.message = payload.message || '';
      snackbar.color = payload.color || '';
    },
  },
  actions: {},
  getters: {},
  modules,
});

import AccountService from './Account.service';

import AccountEntity from './Account.entity';
import AccountFactory from './Account.factory';
import AccountRepository from './Account.http.repository';

import CompanyEntity from './Company.entity';
import CompanyFactory from './Company.factory';
import CompanyRepository from './Company.http.repository';
import CompanyService from './Company.service';

function App() {
  const container = {
    repositories: {
      account: new AccountRepository(),
      company: new CompanyRepository(),
    },
    entities: {
      Account: AccountEntity,
      Company: CompanyEntity,
    },
    factories: {},
    services: {},
  };

  container.factories = {
    account: new AccountFactory(container),
    company: new CompanyFactory(container),
  };

  container.services = {
    account: new AccountService(container),
    company: new CompanyService(container),
  };

  return container;
}

export default App;

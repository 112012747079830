import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class AccountHttpRepository extends BaseRepository {
  async create(account) {
    const data = {
      account: {
        name: account.name,
      },
    };

    const { id } = await API.Account.createAccount(data);
    Object.assign(account, { id });

    this.collection.push(account);

    return account;
  }

  // eslint-disable-next-line class-methods-use-this
  async get(id) {
    const { account } = await API.Account.getAccount({ id });
    return account;
  }
}

export default AccountHttpRepository;

/* eslint-disable max-len,vue/max-len */

/** HOME */
export const R_CORE_HOME = 'app.core';

/** LICENSE */
export const R_LICENSES_DASHBOARD = 'license.dashboard';
export const R_LICENSES = 'license';
export const R_LICENSE = 'license.license-id';
export const R_LICENSE_APPS = 'license.apps';

/** ORGSTRUCTURE */
export const R_ORGSTRUCTURE_DASHBOARD = 'orgstructure.dashboard';
export const R_ORGSTRUCTURE = 'orgstructure';
export const R_ORGSTRUCTURE_COMPANY = 'orgstructure.companies.company-id';
export const R_ORGSTRUCTURE_COMPANY_TEAM = 'orgstructure.companies.company-id.teams.team-id';
export const R_ORGSTRUCTURE_COMPANY_TEAM_EDIT = 'orgstructure.companies.company-id.teams.team-id.edit';
export const R_ORGSTRUCTURE_COMPANY_TEAM_ADD = 'orgstructure.companies.company-id.teams.add';
export const R_ORGSTRUCTURE_COMPANY_TEAM_USERS_ADD = 'orgstructure.companies.company-id.teams.team-id.users.add';
export const R_ORGSTRUCTURE_COMPANY_TEAM_CHILDREN_ADD = 'orgstructure.companies.company-id.teams.team-id.children.add';
export const R_ORGSTRUCTURE_USER_COMPANY = 'orgstructure.user-id.companies.company-id';
export const R_ORGSTRUCTURE_USER_COMPANY_HIERARCHY = 'orgstructure.user-id.companies.company-id.hierarchy';
export const R_ORGSTRUCTURE_USER_COMPANY_COMPETENCY_GROUPS = 'orgstructure.user-id.companies.company-id.competency-groups';
export const R_ORGSTRUCTURE_USER_COMPANY_ANOTHER_CONNECTS = 'orgstructure.user-id.companies.company-id.another-connects';
export const R_ORGSTRUCTURE_USER_COMPANY_CONNECT = 'orgstructure.user-id.companies.company-id.connect';
export const R_ORGSTRUCTURE_POSITION_CREATE = 'orgstructure.position.create';
export const R_ORGSTRUCTURE_POSITION_EDIT = 'orgstructure.position.edit';
export const R_ORGSTRUCTURE_POSITION_VIEW = 'orgstructure.position.view';
export const R_ORGSTRUCTURE_POSITION_SEARCH = 'orgstructure.position.search';
export const R_ORGSTRUCTURE_POSITION_TO_POSITION = 'orgstructure.position.link';
export const R_ORGSTRUCTURE_POSITION_TO_COMPETENCY_GROUP = 'orgstructure.competency-group.link';
export const R_ORGSTRUCTURE_ADD_MANAGER = 'orgstructure.user-id.add-manager';

/** ACCOUNT */
export const R_ACCOUNTS_DASHBOARD = 'accounts.dashboard';
export const R_ACCOUNTS = 'accounts';
export const R_ACCOUNT = 'accounts.account-id';
export const R_ACCOUNT_EDIT = 'accounts.account-id.edit';
export const R_ACCOUNT_USER_ADD = 'accounts.account-id.user.add';
export const R_ACCOUNT_USER_REMOVE = 'accounts.account-id.user.remove';
export const R_ACCOUNT_SYS_COMPANIES = 'accounts.sys-companies';
export const R_ACCOUNT_SYS_COMPANY = 'accounts.sys-company';
export const R_ACCOUNT_SYS_COMPANY_EDIT = 'accounts.sys-company.edit';
export const R_ACCOUNT_SYS_COMPANY_USER_ADD = 'accounts.sys-company.user.add';
export const R_ACCOUNT_SYS_COMPANY_USER_REMOVE = 'accounts.sys-company.user.remove';

/** ACCOUNT v2 */
export const R_ACCOUNT_V2_CREATE = 'accounts.v2.create';
export const R_ACCOUNT_V2_COMPANY_VIEW = 'accounts.v2.view';
export const R_ACCOUNT_V2_COMPANY_VIEW_LICENSE = 'accounts.v2.view.license';
export const R_ACCOUNT_V2_COMPANY_VIEW_LICENSE_CREATE = 'accounts.v2.view.license.create';
export const R_ACCOUNT_V2_COMPANY_VIEW_TEAM_LIST = 'accounts.v2.view.team.list';
export const R_ACCOUNT_V2_COMPANY_VIEW_ADMINS = 'accounts.v2.view.admin.list';
export const R_ACCOUNT_V2_COMPANY_TEAM_CREATE = 'accounts.v2.team.create';
export const R_ACCOUNT_V2_COMPANY_TEAM_EDIT = 'accounts.v2.team.edit';
export const R_ACCOUNT_V2_COMPANY_TEAM_VIEW = 'accounts.v2.team.view';
export const R_ACCOUNT_V2_COMPANY_TEAM_VIEW_STAFF_POSITION = 'accounts.v2.team.staff-position';
export const R_ACCOUNT_V2_COMPANY_IMPORT_INDEX = 'accounts.v2.import.index';
export const R_ACCOUNT_V2_COMPANY_IMPORT_UPLOAD = 'accounts.v2.import.upload';
export const R_ACCOUNT_V2_COMPANY_IMPORT_PROCESSING = 'accounts.v2.import.processing';

/** SYSADMIN */
export const R_SYSADMIN_DASHBOARD = 'sysadmin.dashboard';
export const R_SYSADMIN_ROUTES = 'sysadmin.routes';
export const R_SYSADMIN_ROUTES_CREATE = 'sysadmin.routes.create';
export const R_SYSADMIN_ROUTES_EDIT = 'sysadmin.routes.edit';
export const R_SYSADMIN_ROUTES_VIEW = 'sysadmin.routes.view';

/** COMPETENCY MATRIX */
export const R_COMPETENCY_MATRIX_DASHBOARD = 'competency-matrix.dashboard';
export const R_COMPETENCY_MATRIX_SKILLS = 'competency-matrix.skills';
export const R_COMPETENCY_MATRIX_SKILL = 'competency-matrix.skills.skill-id';
export const R_COMPETENCY_MATRIX_SKILL_CREATE = 'competency-matrix.skills.create';
export const R_COMPETENCY_MATRIX_SKILL_EDIT = 'competency-matrix.skills.edit';
export const R_COMPETENCY_MATRIX_POSITIONS = 'competency-matrix.positions';
export const R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX = 'competency-matrix.positions.user-matrix';
export const R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX_VIEW = 'competency-matrix.positions.user-matrix.view';
export const R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX = 'competency-matrix.positions.company-matrix';
export const R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX_VIEW = 'competency-matrix.positions.company-matrix.view';
export const R_COMPETENCY_MATRIX_POSITION = 'competency-matrix.positions.position-id';
export const R_COMPETENCY_MATRIX_POSITION_CREATE = 'competency-matrix.positions.create';
export const R_COMPETENCY_MATRIX_POSITION_EDIT = 'competency-matrix.positions.edit';
export const R_COMPETENCY_MATRIX_POSITION_COMPETENCIES = 'competency-matrix.positions.position-id.competencies';
export const R_COMPETENCY_MATRIX_POSITION_USERS = 'competency-matrix.positions.position-id.users';
export const R_COMPETENCY_MATRIX_COMPETENCIES = 'competency-matrix.competencies';
export const R_COMPETENCY_MATRIX_COMPETENCY = 'competency-matrix.competencies.competency-id';
export const R_COMPETENCY_MATRIX_COMPETENCY_CREATE = 'competency-matrix.competencies.create';
export const R_COMPETENCY_MATRIX_COMPETENCY_EDIT = 'competency-matrix.competencies.competency-id.edit';
export const R_COMPETENCY_MATRIX_COMPETENCY_SKILLS = 'competency-matrix.competencies.competency-id.skills';
export const R_COMPETENCY_MATRIX_INSTRUMENTS = 'competency-matrix.instruments';
export const R_COMPETENCY_MATRIX_INSTRUMENT = 'competency-matrix.instrument';
export const R_COMPETENCY_MATRIX_INSTRUMENT_CREATE = 'competency-matrix.instrument.create';
export const R_COMPETENCY_MATRIX_INSTRUMENT_EDIT = 'competency-matrix.instrument.edit';

/** GOGHA */
export const R_GOGHA_DASHBOARD = 'gogha.dashboard';
export const R_GOGHA_UPLOAD = 'gogha.upload';

/** AUTHENTICATION */
export const R_AUTHENTICATION_DASHBOARD = 'authentication.dashboard';
export const R_AUTHENTICATION_USERS_CREATE = 'authentication.users.create';
export const R_AUTHENTICATION_USERS_SEARCH = 'authentication.users.search';
export const R_AUTHENTICATION_USER = 'authentication.users.user-id';
export const R_AUTHENTICATION_USER_PHONES_CREATE = 'authentication.users.user-id.phones.create';
export const R_AUTHENTICATION_USER_PUSH_SEND = 'authentication.users.user-id.push.send';
export const R_AUTHENTICATION_USER_EMAILS_CREATE = 'authentication.users.user-id.emails.create';
export const R_AUTHENTICATION_USER_EDIT = 'authentication.users.user-id.edit';
export const R_AUTHENTICATION_ADD_SAP_ID = 'authentication.users.user-id.add-sap-id';

/** INVITATIONS */
export const R_INVITATIONS_DASHBOARD = 'invitations.dashboard';
export const R_INVITATIONS = 'invitations';
export const R_INVITATION = 'invitations.invitation-id';
export const R_INVITATION_CREATE = 'invitations.create';
export const R_INVITATION_EDIT = 'invitations.edit';

/** META */
export const R_META_DASHBOARD = 'meta.dashboard';
export const R_META = 'meta.user-uuid.service-id';
export const R_META_SEARCH = 'meta.search';
export const R_META_CREATE = 'meta.create';
export const R_META_EDIT = 'meta.user-uuid.service-id.edit';

/** NOTIFICATION */
export const R_NOTIFICATION_DASHBOARD = 'notification.dashboard';
export const R_NOTIFICATION_TEMPLATES = 'notification.templates';
export const R_NOTIFICATION_TEMPLATES_CREATE = 'notification.templates.create';
export const R_NOTIFICATION_TEMPLATES_EDIT = 'notification.templates.edit';
export const R_NOTIFICATION_TEMPLATES_VIEW = 'notification.templates.view';

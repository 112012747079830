import { DefaultLayout } from '@front.backoffice/shared';

export default [
  {
    path: '/auth',
    component: DefaultLayout,
    children: [
      {
        path: 'sign-in',
        components: {
          default: () => import('@/views/auth/SignIn.vue'),
        },
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
      },
    ],
  },
];

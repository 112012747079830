import { AppNav, HolyGrailLayout } from '@front.backoffice/shared';
import * as Names from './names';

export default [
  {
    path: '/app/:moduleName/errors',
    component: HolyGrailLayout,
    children: [
      {
        path: '401',
        name: Names.R_ERROR_401,
        component: () => import('@/views/errors/Error401.vue'),
      },

      {
        path: '403',
        name: Names.R_ERROR_403,
        component: () => import('@/views/errors/Error403.vue'),
      },

      {
        path: '404',
        name: Names.R_ERROR_404,
        props: {
          default: true,
        },
        components: {
          nav: AppNav,
          default: () => import('@/views/errors/Error404.vue'),
        },
      },

      {
        path: '500',
        name: Names.R_ERROR_500,
        component: () => import('@/views/errors/Error500.vue'),
      },
    ],
  },
];

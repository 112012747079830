import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class TeamService extends BaseService {
  async createTeam(createTeamDTO) {
    const team = this.factories.team.create(createTeamDTO);
    const errors = [];

    if (team.id.startsWith('GENERATED_')) {
      await this.repositories.team.add(team);

      if (team.parentId) {
        await this.repositories.team.createLink(team, team.parentId);
      }
    }

    return [team, errors];
  }

  async editTeam(editTeamDTO) {
    const oldTeam = await this.repositories.team.find(editTeamDTO.id);
    const newTeam = this.factories.team.create({ ...oldTeam, ...editTeamDTO });
    const errors = [];

    await this.repositories.team.update(newTeam);

    if (oldTeam.parent?.id !== oldTeam.parentId && oldTeam.parentId) {
      if (oldTeam.parent?.id) await this.repositories.team.deleteLink(newTeam, oldTeam.parent.id);
      await this.repositories.team.createLink(newTeam, newTeam.parentId);
    }

    if (oldTeam.parent?.id && !oldTeam.parentId) {
      await this.repositories.team.deleteLink(newTeam, oldTeam.parent.id);
    }

    return [newTeam, errors];
  }

  async getTeam(dto) {
    const response = await this.repositories.team.getTeam(dto.id, dto.companyId);
    response.parentId = response.parent?.id;
    const team = this.factories.team.create(response);
    const errors = [];
    return [team, errors];
  }

  async getTeams(dto) {
    const teams = [];
    const response = await this.repositories.team.getTeams(dto.companyId);
    response.forEach((e) => teams.push(this.factories.team.create(e)));
    const errors = [];
    return [teams, errors];
  }

  async getTeamStaffPositions(dto) {
    const staffPositions = [];
    const response = await this.repositories.staffPosition.getStaffPositions(dto.teamId);
    response.forEach((e) => staffPositions.push(this.factories.staffPosition.create(e)));
    const errors = [];
    return [staffPositions, errors];
  }

  async linkStaffPositions(dto) {
    const response = await this.repositories.staffPosition.linkStaffPositions(dto);
    const errors = [];
    return [response, errors];
  }

  async createStaffPosition(dto) {
    const staffPosition = this.factories.staffPosition.create(dto);
    const errors = [];
    if (staffPosition.id.startsWith('GENERATED_')) {
      await this.repositories.staffPosition.add(staffPosition);
    }
    return [staffPosition, errors];
  }
}

export default TeamService;

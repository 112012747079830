import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class ProcedureFactory extends BaseFactory {
  create(dto, groupId) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const procedure = new this.entities.Procedure({
      id,
      name: dto.name,
      startAt: dto.startAt,
      finishedAt: dto.endAt || dto.finishedAt,
      continuous: dto.continuous || dto.continuous,
      cooldown: dto.cooldown,
      competencyId: dto.competencyId,
      skillId: dto.skillId,
      description: dto.description,
      steps: [],
      state: dto.state,
    }, groupId);

    return procedure;
  }
}

export default ProcedureFactory;

import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class CompanyFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    return new this.entities.Company({
      id,
      name: dto.name,
      active: dto.active,
      accountId: dto.accountId,
      image: dto.image,
    });
  }
}

export default CompanyFactory;

import Accounts from './accounts/app';
import Assessment from './assessment/app';
import License from './license/app';
import Orgstructure from './orgstructure/app';

// TODO: проверить / починить тесты
export {
  Accounts,
  Assessment,
  License,
  Orgstructure,
};

// TODO: конфиги в <PACKAGE_ROOT>
export default function Domain() {
  return {
    Accounts,
    Assessment,
    License,
    Orgstructure,
  };
}

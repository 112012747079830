/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

/**
 * @param data:{companyId: string, importId: string}
 */
export const getImport = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/GetImport', method: methods.HTTP_POST, data });
/**
 * @param data:{ companyId: string, importId: string }
 */
export const prepareUrl = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/PrepareUrl', method: methods.HTTP_POST, data });
/**
 * @param data:{ companyId: string, importId: string, type: 'IMPORT_FILE_TYPE_USERS' | 'IMPORT_FILE_TYPE_STAFF_POSITIONS' | 'IMPORT_FILE_TYPE_TEAMS', fileId: string, filename: string }
 */
export const addImportFile = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/AddImportFile', method: methods.HTTP_POST, data });
/**
 * @param data:{ companyId: string, importId: string, resolveFiles: [string] }
 */
export const runImport = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/RunImport', method: methods.HTTP_POST, data });
/**
 * @param data:{ companyId: string, importId: string, importFileId: string }
 */
export const removeImportFile = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/RemoveImportFile', method: methods.HTTP_POST, data });
export const ImportsIndex = (data = {}, options) => transport({ url: '/twirp/tt.m9t.filetostream.FileimportAPI/ImportsIndex', method: methods.HTTP_POST, data, ...options });
export const CreateImport = (data = {}, options) => transport({ url: '/twirp/tt.m9t.filetostream.FileimportAPI/CreateImport', method: methods.HTTP_POST, data, ...options });
/**
 * @param data:{ companyId: string, importFileId: string }
 */
export const getImportFileTempUrl = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/GetTemporalImportFileUrl', method: methods.HTTP_POST, data });
export const getReportImportFileUrl = (data) => transport({ url: '/twirp/tt.core.integration.filetostream.v1.FileimportAPI/GetTemporalReportFileUrl', method: methods.HTTP_POST, data });

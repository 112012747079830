import { BaseService } from '../common/BaseService';

// TODO: errors, entity validation
class LicenseAppService extends BaseService {
  async index() {
    const response = await this.repositories.licenseApp.index();
    const licenses = response.apps.map((l) => {
      const license = l;
      if (!license.bundleName) { license.bundleName = 'empty'; }
      return this.factories.licenseApp.create(l);
    });
    const errors = [];

    return [
      licenses,
      errors,
    ];
  }
}

export default LicenseAppService;

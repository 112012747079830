<template>
  <TTModuleMenu
    :items-tree="menuItems"
    hide-sign-out
  />
</template>

<script>
export default {
  name: 'AppEngagementAsideLeft',

  inject: ['Names'],

  computed: {
    menuItems() {
      const items = [
        [
          {
            title: 'Главная',
            to: { name: this.Names.R_ENGAGEMENT_HOME },
            icon: 'fal fa-columns',
            exact: true,
          },
          {
            icon: 'fal fa-poll-people fa-fw',
            title: 'Опросы',
            exact: true,
            children: [
              {
                icon: 'fal fa-list',
                title: 'Список опросов',
                to: { name: this.Names.R_ENGAGEMENT_SURVEYS },
                exact: true,
              },
              {
                icon: 'fal fa-plus',
                title: 'Создать опрос',
                to: { name: this.Names.R_ENGAGEMENT_SURVEYS_CREATE },
              },
            ],
          },
        ],
      ];

      return items;
    },
  },
};
</script>

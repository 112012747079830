import Vue from 'vue';
import BottleJS from 'bottlejs';
import API from '@front.backoffice/api';
import Domain from '@front.backoffice/domain';
import FeatureFlag from '@/plugins/feature-flag';
import errorHandler, { snackError, snackSuccess } from '../../common/errorHandler';
import errorHandler404 from '../../common/errorHandler404';

const bottle = new BottleJS();
bottle.service('api', API);
bottle.service('ddd', Domain);
bottle.factory('notify', () => ({
  errorHandler,
  snackError,
  snackSuccess,
}));
bottle.factory('redirect', () => ({
  errorHandler404,
}));

let flags = [];

bottle.defer(async () => {
  const { features } = await API().FeaturesApi.featuresGet();
  flags = features;
});

bottle.resolve('flags');

bottle.factory('ff', () => new FeatureFlag(flags));

const install = (vue) => {
  Object.defineProperty(vue.prototype, '$di', {
    get() {
      return bottle.container;
    },
  });
};

Vue.use(install);

export default bottle;

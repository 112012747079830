import { AccessToken } from '@front.backoffice/api';

const accessToken = (to, from, next) => {
  const isExpired = AccessToken.isExpired();
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);

  if (!isPublic && isExpired) {
    return next({
      path: '/auth/sign-in',
      query: { redirect: to.fullPath },
    });
  }

  if (!isExpired && onlyWhenLoggedOut) {
    return next('/home');
  }

  return next();
};

export default accessToken;

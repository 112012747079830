/* eslint-disable object-curly-newline, max-len, vue/max-len */
import transport from './transport';
import * as methods from '../../common/constants';

export const CompetenciesByCompany = ({ data = {}, ...options } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/ByCompany', method: methods.HTTP_POST, data, ...options });
export const CompetenciesCreate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/Create', method: methods.HTTP_POST, data });
export const CompetenciesDelete = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/Delete', method: methods.HTTP_POST, data });
export const CompetenciesGet = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/Get', method: methods.HTTP_POST, data });
export const CompetenciesIndex = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/Index', method: methods.HTTP_POST, data });
export const CompetenciesIndexWithPagination = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/IndexWithPagination', method: methods.HTTP_POST, data });
export const CompetenciesUpdate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencies.v1.CompetenciesAPI/Update', method: methods.HTTP_POST, data });
export const CompetencySkillsCreate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Create', method: methods.HTTP_POST, data });
export const CompetencySkillsDelete = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Delete', method: methods.HTTP_POST, data });
export const CompetencySkillsGet = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Get', method: methods.HTTP_POST, data });
export const CompetencySkillsGetRequiredLevel = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/GetRequiredLevel', method: methods.HTTP_POST, data });
export const CompetencySkillsIndex = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Index', method: methods.HTTP_POST, data });
export const CompetencySkillsUpdate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Update', method: methods.HTTP_POST, data });
export const InstrumentsCreate = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.instruments.v1.InstrumentsAPI/Create', method: methods.HTTP_POST, data });
export const InstrumentsDelete = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.instruments.v1.InstrumentsAPI/Delete', method: methods.HTTP_POST, data });
export const InstrumentsGet = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.instruments.v1.InstrumentsAPI/Get', method: methods.HTTP_POST, data });
export const InstrumentsIndex = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.instruments.v1.InstrumentsAPI/Index', method: methods.HTTP_POST, data });
export const InstrumentsUpdate = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.instruments.v1.InstrumentsAPI/Update', method: methods.HTTP_POST, data });
export const PositionsAddCompetency = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/AddCompetency', method: methods.HTTP_POST, data });
export const PositionsAddUser = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/AddUser', method: methods.HTTP_POST, data });
export const PositionsCompanyMatrix = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/CompanyMatrix', method: methods.HTTP_POST, data });
export const PositionsCreate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/Create', method: methods.HTTP_POST, data });
export const PositionsDelete = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/Delete', method: methods.HTTP_POST, data });
export const PositionsForCompany = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/ForCompany', method: methods.HTTP_POST, data });
export const PositionsGet = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/Get', method: methods.HTTP_POST, data });
export const PositionsGetUsersCountForCompany = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/GetUsersCountForCompany', method: methods.HTTP_POST, data });
export const PositionsGetUsersForCompany = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/ForCompany', method: methods.HTTP_POST, data });
export const PositionsIndex = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/Index', method: methods.HTTP_POST, data });
export const V4PositionsIndex = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencygroups.v4.CompetencygroupsAPI/Index', method: methods.HTTP_POST, data });
export const PositionsRemoveCompetency = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/RemoveCompetency', method: methods.HTTP_POST, data });
export const PositionsRemoveUser = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/RemoveUser', method: methods.HTTP_POST, data });
export const PositionsUpdate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/Update', method: methods.HTTP_POST, data });
export const PositionsUserMatrix = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.positions.v1.PositionsAPI/UserMatrix', method: methods.HTTP_POST, data });
export const SkillsCreate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/Create', method: methods.HTTP_POST, data });
export const SkillsDelete = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/Delete', method: methods.HTTP_POST, data });
export const SkillsGet = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/Get', method: methods.HTTP_POST, data });
export const SkillsIndex = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/Index', method: methods.HTTP_POST, data });
export const SkillsIsSkillAvailable = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/IsSkillAvailable', method: methods.HTTP_POST, data });
export const SkillsUpdate = (data = {}) => transport({ url: '/twirp/tt.core.competencymatrix.skills.v1.SkillsAPI/Update', method: methods.HTTP_POST, data });
export const V2CompetencySkillsSkills = ({ data = {} } = {}) => transport({ url: '/twirp/tt.core.competencymatrix.competencyskills.v2.CompetencyskillsAPI/Skills', method: methods.HTTP_POST, data });

export const SearchCompetencyGroups = (data = {}) => transport({ url: '/twirp/tt.m9t.competencymatrix.SearchAPI/SearchCompetencyGroups', method: methods.HTTP_POST, data });
export const CompetenciesSearch = (data = {}) => transport({ url: '/twirp/tt.m9t.competencymatrix.SearchAPI/SearchCompetencies', method: methods.HTTP_POST, data });
export const SkillsSearch = (data = {}) => transport({ url: '/twirp/tt.m9t.competencymatrix.SearchAPI/SearchSkills', method: methods.HTTP_POST, data });
export const GetCompetencyGroupsByIds = (data = {}) => transport({ url: '/twirp/tt.m9t.competencygroups.CompetencygroupsAPI/GetCompetencyGroupsByIds', method: methods.HTTP_POST, data });
export const SearchCompetencyGroupsByCompany = (data = {}) => transport({ url: '/twirp/tt.m9t.competencymatrix.SearchAPI/SearchCompetencyGroupsByCompany', method: methods.HTTP_POST, data });

import TeamService from './Team.service';
import TeamEntity from './Team.entity';
import TeamFactory from './Team.factory';
import TeamRepository from './Team.http.repository';

import StaffPositionEntity from './StaffPosition.entity';
import StaffPositionFactory from './StaffPosition.factory';
import StaffPositionRepository from './StaffPosition.http.repository';

function App() {
  const container = {
    repositories: {
      team: new TeamRepository(),
      staffPosition: new StaffPositionRepository(),
    },
    entities: {
      Team: TeamEntity,
      StaffPosition: StaffPositionEntity,
    },
    factories: {},
    services: {},
  };

  container.factories = {
    team: new TeamFactory(container),
    staffPosition: new StaffPositionFactory(container),
  };

  container.services = {
    team: new TeamService(container),
  };

  return container;
}

export default App;

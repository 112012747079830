import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class LicenseAppHttpRepository extends BaseRepository {
  // eslint-disable-next-line class-methods-use-this
  index() {
    return API.License.indexApp();
  }
}

export default LicenseAppHttpRepository;

export default {
  // Пример использования
  // ratingFullStar: 'fas fa-star',
  arrowToBottom: 'fal fa-arrow-to-bottom',
  importFile: 'fal fa-file-import',
  plus: 'fal fa-plus',
  plusCircle: 'fal fa-plus-circle',
  minusCircle: 'fal fa-minus-circle',
  arrowLeft: 'fal fa-arrow-left',
  ellipsis: 'fal fa-ellipsis-h',
  search: 'fal fa-search',
  sitemap: 'fal fa-sitemap',
  bars: 'fal fa-bars',
  archive: 'fal fa-archive',
};

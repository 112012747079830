import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class StaffPositionHttpRepository extends BaseRepository {
  async add(staffPosition) {
    const data = {
      id: staffPosition.id,
      name: staffPosition.name,
      teamId: staffPosition.teamId,
      competencyGroupIds: staffPosition.competencyGroupIds,
      externalId: staffPosition.externalId,
      role: staffPosition.externalId,
    };
    const { id } = await API.Orgstructure.StaffPositionCreateV2(data);
    Object.assign(staffPosition, { id });

    this.collection.push(staffPosition);

    return staffPosition;
  }

  // eslint-disable-next-line
  async getStaffPositions(teamId) {
    const { staffPositions } = await API.Orgstructure.teamStaffPosition({ teamId });
    return staffPositions;
  }

  // eslint-disable-next-line
  async linkStaffPositions(dto) {
    const response = await API.Orgstructure.linkStaffPositions(dto);
    return response;
  }
}

export default StaffPositionHttpRepository;

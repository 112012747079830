import store from '@/plugins/vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';

export const snackSuccess = (message) => store.commit(toggleSnack, {
  color: 'success',
  message,
}, { root: true });

export const snackError = (message) => store.commit(toggleSnack, {
  color: 'error',
  message,
}, { root: true });

export default function errorHandler(err) {
  const errResponse = err.response;

  if (errResponse) {
    console.warn(errResponse);

    if (errResponse.data.msg) {
      const message = `${errResponse.status}. ${errResponse.data.msg || 'Произошла ошибка'}`;
      snackError(message);
      return;
    }

    if (errResponse.data.fields) {
      const message = JSON.stringify(errResponse.data.fields);
      snackError(`${errResponse.status}. ${message}`);
      return;
    }
  }
  // axios irrtum 'Error: Request failed with status code xxx'
  snackError(err);
}

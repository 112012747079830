import * as API from '@front.backoffice/api';
import { BaseRepository } from '../common/BaseRepository';

class ProcedureHttpRepository extends BaseRepository {
  async add(procedure) {
    const data = {
      procedure: {
        name: procedure.name,
        skillId: procedure.skillId,
        competencyId: procedure.competencyId,
        cooldown: procedure.cooldown,
        continuous: procedure.continuous,
        startAt: procedure.startAt,
        finishedAt: procedure.finishedAt,
        description: procedure.description,
      },
    };
    const { id } = await API.Assessment.ProceduresCreate({ data });
    Object.assign(procedure, { id });
    this.collection.push(procedure);
  }

  async update(procedure) {
    const data = {
      id: procedure.id,
      procedure: {
        cooldown: procedure.cooldown,
        continuous: procedure.continuous,
        startAt: procedure.startAt,
        finishedAt: procedure.finishedAt,
        description: procedure.description,
      },
    };
    await API.Assessment.ProceduresUpdate({ data });
    this.collection.push(procedure);
  }

  // eslint-disable-next-line
  async get(id) {
    const { procedure } = await API.Assessment.ProceduresGet({ data: { id } });
    return procedure;
  }

  // eslint-disable-next-line
  async find(id) {
    throw new Error('Not Implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  async archive(procedure) {
    const data = {
      id: `${procedure.id}`,
    };

    try {
      await API.Assessment.ProceduresArchive({ data });
      return [true, ''];
    } catch (error) {
      return [false, error.response?.data.msg ?? 'Причина не известна'];
    }
  }
}

export default ProcedureHttpRepository;

import {
  camelCase, mapKeys, mapValues, isObject, isArray,
} from 'lodash';

function toCamelCase(obj) {
  if (isArray(obj)) {
    return obj.map((i) => toCamelCase(i));
  }

  if (isObject(obj)) {
    return mapValues(
      mapKeys(obj, (v, k) => (!k.startsWith('_') && !k.includes('-') ? camelCase(k) : k)),
      (value) => toCamelCase(value),
    );
  }

  return obj;
}

export default toCamelCase;

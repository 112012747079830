import { v4 } from 'uuid';
import { BaseFactory } from '../common/BaseFactory';

class LicenseFactory extends BaseFactory {
  create(dto) {
    const id = dto.id ? dto.id : `GENERATED_${v4()}`;

    const entity = new this.entities.License({
      id,
      appName: dto.appName,
      licensee: { ...dto.licensee },
      duration: { ...dto.duration },
      totalUsers: dto.totalUsers,
      involvedUsers: dto.involvedUsers,
      active: dto.active,
      bundleName: dto.bundleName || dto.appBundleName,
      type: dto.licensee.accountId ? 'account' : 'company',
    });

    return entity;
  }
}

export default LicenseFactory;

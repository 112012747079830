// Note: у группы нет имени, wtf?
// eslint-disable-next-line max-len, vue/max-len
// @see: https://github.com/severgroup-tt/assessment/blob/0d6dff38f4d7def32bba9dd042c4ac27b0d7f7e6/rpc/tt/learning/groups/v1/common.proto#L8
const DEFAULT_GROUP = {
  id: null,
  name: null,
  companyId: null,
  teamId: null,
  userId: null,
  procedures: null,
};

class GroupEntity {
  constructor(group = DEFAULT_GROUP) {
    this.id = group.id;
    this.name = group.name;
    this.companyId = group.companyId;
    this.teamId = group.teamId;
    this.userId = group.userId;
    this.procedures = group.procedures;
  }

  addProcedures(procedures) {
    procedures.forEach((procedure) => this.addProcedure(procedure));
  }

  addProcedure(procedure) {
    this.procedures.push(procedure);
  }
}

export default GroupEntity;

<template>
  <VApp id="AppLayout">
    <RouterView name="nav" />

    <RouterView name="aside-left" />

    <VMain>
      <RouterView />

      <RouterView name="content-footer" />
    </VMain>

    <RouterView name="aside-right" />

    <RouterView name="footer" />
  </VApp>
</template>

<script>
export default {
  name: 'AppLayout',
};
</script>
